import {
  Box,
  HStack,
  VStack,
  Text,
  Heading,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  IconButton,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

import { useRareCare } from "src/contexts/rarecare.context";
import { centimeterToFeet, getAge, gramToLibs } from "src/utils";
import { KebabMenuIcon } from "src/components/Icons";
import useDimensions from "src/hooks/useDimensions";
import VitalsEditDrawer from "./VitalsEditDrawer";
import DiagnosisEditDrawer from "./DiagnosisEditDrawer";
import { useAuth } from "src/contexts/auth.context";

const bloodTypes = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

export default function CaseSummary() {
  const {
    user: { role },
  } = useAuth();
  const { Case, User: user } = useRareCare();
  const { isOpen: editVitalsIsOpen, onOpen: openEditVitals, onClose: closeEditVitals } = useDisclosure();
  const { isOpen: editDiagnosisIsOpen, onOpen: openEditDiagnosis, onClose: closeEditDiagnosis } = useDisclosure();

  const { isMobile } = useDimensions();

  if (!Case || !user) return null;

  const vitals = Case?.Vitals || null;
  const medicationsArr = Case?.Medications || null;
  const medicationsStr = medicationsArr?.map((item) => item.Medication).join(", ");

  const fullName = `${user.name} ${user.familyName}`;
  const userAge = user.birthdate ? getAge(user.birthdate) : null;

  return (
    <VStack
      flex="2"
      align="left"
      background="white"
      minWidth={["300px", "300px", "500px"]}
      maxWidth="100%"
      padding="40px"
      paddingTop="0px"
      boxShadow="0px 0px 10px rgba(35, 70, 112, 0.03)"
      borderRadius="8px"
    >
      <Flex direction="row-reverse">
        {role === "patient" ? (
          <Menu>
            <MenuButton as={IconButton} variant="ghost" icon={<KebabMenuIcon />} />
            <MenuList>
              <MenuItem onClick={openEditVitals}>Edit vitals</MenuItem>
              <MenuItem onClick={openEditDiagnosis}>Edit diagnosis</MenuItem>
            </MenuList>
          </Menu>
        ) : (
          <Box marginTop="20px" />
        )}
      </Flex>

      <VStack align="left" gap="20px" flex="1">
        <Flex gap={["20px", "20px", "40px"]} direction={["column", "column", "row"]} marginBottom="20px">
          <HStack spacing="24px">
            <Avatar size={"lg"} name={fullName} src={user.avatarUrl} />
            <VStack align="left">
              <Heading fontSize="20px" lineHeight="24px" margin="0">
                {fullName}
              </Heading>
              <Text color="#496CA1" fontWeight="800">
                {userAge ? (
                  `${userAge} years old`
                ) : (
                  <Link to="/settings">
                    Add your birth date <EditIcon boxSize={4} />
                  </Link>
                )}
              </Text>
            </VStack>
          </HStack>
          <HStack>
            <HStack spacing="24px">
              <Box border="1px solid #B8BCD1" height="32px" />
              <Vital type="Blood" value={vitals ? bloodTypes[vitals.BloodType] : "Not provided"} />
              <Vital type="Weight" value={vitals ? gramToLibs(vitals.Weight) + " LBS" : "Not provided"} />
              <Vital type="Height" value={vitals ? centimeterToFeet(vitals.Height) + " FT" : "Not provided"} />
            </HStack>
            {role === "patient" && (
              <Tooltip label="Edit vitals">
                <Button id="edit-vitals" as={EditIcon} boxSize={12} variant="ghost" onClick={openEditVitals} />
              </Tooltip>
            )}
          </HStack>
        </Flex>

        <HStack spacing="13px">
          <Heading as="h3" fontSize="14px" color="#496CA1" minWidth="100px">
            Diagnosis:
          </Heading>
          {Case.MainDiagnosis ? (
            <>
              <Tooltip label={isMobile ? Case.MainDiagnosis : ""}>
                <Text isTruncated>{Case.MainDiagnosis} </Text>
              </Tooltip>
              {role === "patient" && (
                <Tooltip label="Edit diagnosis">
                  <Button
                    id="edit-diagnosis"
                    as={EditIcon}
                    boxSize={12}
                    variant="ghost"
                    margin="0px"
                    padding="0px"
                    width="16px"
                    height="16px"
                    onClick={openEditDiagnosis}
                  />
                </Tooltip>
              )}
            </>
          ) : (
            <Button variant="ghost" onClick={openEditDiagnosis}>
              <Text color="#496CA1">
                Click to add diagnosis <EditIcon boxSize={4} />
              </Text>
            </Button>
          )}
        </HStack>

        <HStack spacing="13px">
          <Heading as="h3" fontSize="14px" color="#496CA1" minWidth="100px">
            Medications:
          </Heading>
          <Tooltip label={medicationsStr}>
            <Text maxWidth="90%" isTruncated>
              {medicationsStr || "Not provided"}
            </Text>
          </Tooltip>
        </HStack>

        {/* <HStack spacing="13px">
          <Heading as="h3" fontSize="14px" color="#496CA1" minWidth="100px">
            Providers:
          </Heading>
          <HStack>
            <Avatar size="sm" name="Brooklyn Simmons" src={""} />
            <Avatar size="sm" name="Jhon Doe" src={""} />
            <Avatar size="sm" name="Lucy Mesa" src={""} />
            <Avatar size="sm" name="Willy William" src={""} />
          </HStack>
        </HStack> */}
      </VStack>
      <VitalsEditDrawer initialValue={vitals || null} isOpen={editVitalsIsOpen} onClose={closeEditVitals} />
      <DiagnosisEditDrawer
        initialValue={Case.MainDiagnosis}
        isOpen={editDiagnosisIsOpen}
        onClose={closeEditDiagnosis}
      />
    </VStack>
  );
}

function Vital({ type, value }: { type: string; value: string | number }) {
  return (
    <VStack>
      <Text color="#496CA1" fontWeight="400" margin="0">
        {type}
      </Text>
      <Text color="#496CA1" fontWeight="800">
        {value}
      </Text>
    </VStack>
  );
}
