import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import { Buffer } from "buffer";

const axios = setupCache(Axios);

const FileDownloadCacheOptions = {
  cache: {
    methods: ["post"],
    ttl: 1000 * 60 * 30, // 30 minutes
  },
};

interface ResponseData {
  error: boolean;
  message: string;
  data: any;
}

export class RareCareCase {
  CaseID: string;
  UserID: string;
  APIEndpoint: string;
  Patient: RareCare.Patient;

  constructor(UserID: string) {
    this.UserID = UserID;
    this.CaseID = "";
    this.Patient = {} as RareCare.Patient;
    this.APIEndpoint = process.env.REACT_APP_RARECARE_API || "https://api.rarecare.com";
  }

  async Initialize() {
    this.Patient = await this.getPatient(this.UserID);
    this.CaseID = this.Patient.CaseID;
  }

  async Post(url: string, postData: any, options?: any) {
    const { data } = await axios.post(this.APIEndpoint + url, postData, options);
    return data;
  }

  async getPatient(userID: string): Promise<RareCare.Patient> {
    return this.Post("/patient/get", {
      UserID: userID,
    })
      .then((data) => {
        return data.Payload;
      })
      .catch((err) => {
        throw err;
      });
  }

  async getCase(): Promise<RareCare.Case> {
    return this.Post("/case/get", {
      CaseID: this.CaseID,
    })
      .then((data) => {
        if (!data.ICD10Codes) {
          data.ICD10Codes = {
            Diagnoses: [],
            Symptoms: [],
          };
        }
        if (!data.ICD10Codes.Diagnoses) data.ICD10Codes.Diagnoses = [];
        if (!data.ICD10Codes.Symptoms) data.ICD10Codes.Symptoms = [];
        return data;
      })
      .catch((error) => {
        console.error(error);
        return {} as RareCare.Case;
      });
  }

  async changeStatus(status: string) {
    return this.Post("/rarecare/case/changestatus", {
      CaseID: this.CaseID,
      CaseStatus: status,
    });
  }

  update(data: any) {
    return this.Post("/rarecare/case/update", {
      CaseID: this.CaseID,
      ...data,
    });
  }

  async updateCaseTimeline(Timeline: any): Promise<any> {
    return this.Post("/timeline/update", {
      CaseID: this.CaseID,
      Timeline: Timeline,
    });
  }

  async updateCaseMedications(Medications: any): Promise<any> {
    return this.Post("/rarecare/case/uploadmedications", {
      CaseID: this.CaseID,
      Medications: Medications,
    });
  }

  async updateCaseICD10Codes(ICD10Codes: any): Promise<any> {
    return this.Post("/rarecare/case/uploadicd10codes", {
      CaseID: this.CaseID,
      ICD10Codes: ICD10Codes,
    });
  }

  async updateCaseVitals(data: RareCare.CaseVital): Promise<ResponseData> {
    try {
      const response = await this.Post("/case/vitals/update", {
        CaseID: this.CaseID,
        Vitals: data,
      });

      if (response.Error) {
        throw new Error("An error has occurred. Please try again");
      }

      return {
        error: false,
        message: "Updated successfully!",
        data: response,
      };
    } catch (error: any) {
      console.error(error);
      return {
        error: true,
        message: error.message,
        data: error?.response?.data,
      };
    }
  }

  async updateCaseDetails(data: Record<string, string>): Promise<ResponseData> {
    try {
      const response = await this.Post("/case/details/update", {
        CaseID: this.CaseID,
        ...data,
      });

      if (response.Error) {
        throw new Error("An error has occurred. Please try again");
      }

      return {
        error: false,
        message: "Updated successfully!",
        data: response,
      };
    } catch (error: any) {
      console.error(error);
      return {
        error: true,
        message: error.message,
        data: error?.response?.data,
      };
    }
  }

  async addTimelineItem(data: { date: string }): Promise<ResponseData> {
    try {
      const response = await this.Post("/rarecare/timeline/timepoint/add", {
        CaseID: this.CaseID,
        Date: data.date,
      });

      if (response.Error) {
        throw new Error("An error has occurred. Please try again");
      }

      return {
        error: false,
        message: "Added successfully!",
        data: response,
      };
    } catch (error: any) {
      console.error(error);
      return {
        error: true,
        message: error.message,
        data: error?.response?.data,
      };
    }
  }

  async addTimelineTimepoint(data: Timeline.AddTimelineData): Promise<ResponseData> {
    try {
      const urlMapper = {
        Diagnostics: ["Diagnostic", "/rarecare/timeline/timepoint/add_diagnostic"],
        Procedures: ["Procedure", "/rarecare/timeline/timepoint/add_procedure"],
        Symptoms: ["Symptom", "/rarecare/timeline/timepoint/add_symptom"],
      };
      const [type, url] = urlMapper[data.type];

      const response = await this.Post(url, {
        CaseID: this.CaseID,
        Date: data.date,
        [`${type}Name`]: data.name,
        [`${type}Description`]: data.description,
      });

      if (response.Error) {
        throw new Error("An error has occurred. Please try again");
      }

      return {
        error: false,
        message: "Added successfully!",
        data: response,
      };
    } catch (error: any) {
      console.error(error);
      return {
        error: true,
        message: error.message,
        data: error?.response?.data,
      };
    }
  }

  async appendCaseDescription(description: Array<string>): Promise<any> {
    return this.Post("/case/description/append", {
      CaseID: this.CaseID,
      Description: "<p>" + description.join("</p><p>") + "</p>",
    });
  }

  async updateCaseHPOCodes(HPOCodes: any): Promise<any> {
    return this.Post("/rarecare/case/uploadHPOcodes", {
      CaseID: this.CaseID,
      HPOCodes: HPOCodes,
    });
  }

  async getCaseFileList(): Promise<any> {
    return this.Post("/case/files/list", {
      CaseID: this.CaseID,
    }).then((res) => {
      let fileList: any = [];

      res.Payload?.forEach((file: any) => {
        const fileName = file.Filename.split("/");
        if (fileName[1] !== "") {
          file.Filename = fileName[1];
          fileList.push(file);
        }
      });
      return fileList;
    });
  }

  async getFileLabels(fileName: string): Promise<any> {
    return this.Post(
      "/emr/file/labels/get",
      {
        CaseID: this.CaseID,
        Filename: fileName,
      },
      FileDownloadCacheOptions
    );
  }

  async searchCase(keywords: string): Promise<any> {
    return this.Post("/case/search", {
      CaseID: this.CaseID,
      Keywords: keywords
        .split(" ")
        .map((k) => k.trim())
        .filter(Boolean),
    });
  }

  async getSearchHistory(): Promise<any> {
    return this.Post(
      "/case/search/history",
      {
        CaseID: this.CaseID,
      },
      FileDownloadCacheOptions
    );
  }

  async uploadFile(fileName: string, file: string): Promise<any> {
    return this.Post("/case/files/upload", {
      CaseID: this.CaseID,
      Filename: fileName,
      B64Data: file,
    });
  }

  async downloadFile(Filename: string): Promise<Buffer> {
    return this.Post(
      "/case/files/download",
      {
        CaseID: this.CaseID,
        Filename: Filename,
      },
      FileDownloadCacheOptions
    ).then((res) => {
      return Buffer.from(res.Payload, "base64");
    });
  }

  async uploadAvatar(file: string): Promise<any> {
    return this.Post("/patient/avatar/upload", {
      UserID: this.UserID,
      B64Data: file,
    });
  }

  async getAvatar(): Promise<string> {
    return this.Post("/patient/avatar/get", {
      UserID: this.UserID,
    }).then((res) => {
      const avatar = Buffer.from(res.Payload, "base64");
      const file = new File([avatar], "avatar.jpg", { type: "image/jpg" });
      return URL.createObjectURL(file);
    });
  }

  async getICD10Description(ICD10Code: string): Promise<any> {
    return this.Post("/icd10/get", {
      Code: ICD10Code,
    }).then((res) => {
      return res.Payload;
    });
  }
}
