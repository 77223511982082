import { Box, Flex, Grid } from "@chakra-ui/react";
import { FunctionComponent } from "react";

// import MedicationsTimeline from "../components/Medications/MedicationsTimeline";
// import MedicationsTable from "../components/Medications/MedicationsTable";
// import { ICD10Table } from "../components/ICD10";
// import HPOTable from "../components/HPO/HPOTable";
// import Medications from "src/components/DashboardCards/Medications";

import Layout from "../components/Layout";
import MedicalJourneyTimeline from "../components/DashboardCards/ActivityTimeline";
import CaseActions from "../components/DashboardCards/CaseActions";
import CaseSummary from "../components/DashboardCards/CaseSummary";
import MyMedications from "../components/DashboardCards/MyMedications";
import EmptyState from "../components/EmptyState";
import Loader from "../components/Loader";
import { useRareCare } from "../contexts/rarecare.context";
import AccountProgressStepper from "src/components/AccountProgressStepper";
import AppSearch from "src/components/AppSearch";
import CaseDescriptionAccordion from "src/components/CaseDescriptionAccordion";
import { useAuth } from "src/contexts/auth.context";
import { Navigate, useSearchParams } from "react-router-dom";
import { useCase } from "src/contexts/case.context";

const Dashboard: FunctionComponent = () => {
  const { user } = useAuth();
  const { User, Case, loading, error, errorMessage } = useRareCare();

  if (user.role === "doctor" && !Case) {
    return <Navigate to="/care-team-dashboard" replace />;
  }

  if (error) {
    return (
      <Grid placeItems="center" width="100vw" height="100vh" background="white">
        <EmptyState
          header="An error happened"
          description={errorMessage || "Oops! An error happened. Try again later"}
        />
      </Grid>
    );
  }

  if (loading) {
    return (
      <Box display="grid" placeItems="center" width="100vw" height="100vh">
        <Loader />
      </Box>
    );
  }

  if (!Case || !User) {
    return null;
  }

  return (
    <Layout>
      <Flex gap="16px" direction="column" marginBottom="100px">
        <AppSearch />
        <AccountProgressStepper />

        <Flex direction={["column", "column", "column", "row"]} flexWrap="wrap" gap="16px">
          <CaseSummary />
          <CaseActions />
        </Flex>

        <CaseDescriptionAccordion description={Case.Description} />

        <Flex gap="16px" direction={["column", "column", "column", "column", "row"]}>
          <MyMedications />
          <MedicalJourneyTimeline />
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Dashboard;
