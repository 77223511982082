import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_RARECARE_API || "https://api.rarecare.com";

export async function getPatient(userID: string): Promise<RareCare.Patient> {
  const endpoint = API_BASE_URL + "/patient/get";
  return axios.post(endpoint, { UserID: userID }).then(({ data }: any) => {
    return data.Payload;
  });
}

export async function signUpPatient(data: any) {
  const endpoint = API_BASE_URL + "/patient/new";
  return axios.post(endpoint, data).then(({ data }: any) => {
    return data;
  });
}
