import { Routes, Route, Navigate } from "react-router-dom";

import Loader from "../components/Loader";
import Dashboard from "../pages/Dashboard";
import CareTeamDashboard from "../pages/CareTeamDashboard";
import Login from "../pages/Login";
import Settings from "../pages/Settings";
import { useAuth } from "../contexts/auth.context";
import MyFiles from "src/pages/MyFiles";
import MyMedicationsPage from "src/pages/MyMedications";
import ForgotPasswordForm from "src/pages/PasswordReset";
import { Link } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";
import Chatbot from "src/pages/Chatbot";
import TermsAndConditions from "src/pages/TermsAndConditions";
import ConsentForm from "src/pages/ConsentForm";
import SignupCard from "src/pages/Signup";

export interface RouterType {
  title: string;
  path?: string;
  element: React.FunctionComponent;
  index?: boolean;
  isProtected: boolean;
  roles?: string[];
}

const routes: RouterType[] = [
  {
    title: "Dashboard",
    index: true,
    element: Dashboard,
    isProtected: true,
  },
  {
    title: "Dashboard",
    path: "care-team-dashboard",
    element: CareTeamDashboard,
    isProtected: true,
    roles: ["doctor"],
  },
  {
    title: "Chatbot",
    path: "chatbot",
    element: Chatbot,
    isProtected: false,
    // roles: ["doctor"],
  },
  {
    title: "My files",
    path: "my-files",
    element: MyFiles,
    isProtected: true,
  },
  {
    title: "My medications",
    path: "my-medications",
    element: MyMedicationsPage,
    isProtected: true,
  },
  {
    title: "Login",
    path: "login",
    element: Login,
    isProtected: false,
  },
  {
    title: "Signup",
    path: "signup",
    element: SignupCard,
    isProtected: false,
  },
  {
    title: "Settings",
    path: "settings",
    element: Settings,
    isProtected: true,
  },
  {
    title: "Forgot password",
    path: "password-reset",
    element: ForgotPasswordForm,
    isProtected: false,
  },
  {
    title: "Terms & Conditions",
    path: "terms-and-conditions",
    element: TermsAndConditions,
    isProtected: false,
  },
  {
    title: "Consent form",
    path: "consent-form",
    element: ConsentForm,
    isProtected: false,
  },
  {
    title: "Page not found",
    path: "*",
    element: () => <div>Page not found.</div>,
    isProtected: false,
  },
];

const ProtectedRoute = (props: React.PropsWithChildren<{ routeData: RouterType }>) => {
  const { isLoading, isAuthenticated, user } = useAuth();
  const { roles } = props.routeData;

  if (roles && !roles.includes(user.role as string)) {
    return (
      <Box w="100vw" h="100vh" display="grid" placeItems="center">
        <Box textAlign="center">
          <Text fontSize="26px">Not authorized!</Text>
          <Link to="/"> ← Go back</Link>
        </Box>
      </Box>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <>{props.children}</>;
};

const Router: React.FunctionComponent = () => {
  return (
    <Routes>
      {routes.map((route) => {
        const { title, path, index, element, isProtected } = route;
        const Element = element;
        if (isProtected) {
          const ProtectedElement = () => (
            <ProtectedRoute routeData={route}>
              <Element />
            </ProtectedRoute>
          );
          return <Route index={Boolean(index)} key={title} path={path} element={<ProtectedElement />} />;
        } else {
          return <Route key={title} path={path} element={<Element />} />;
        }
      })}
    </Routes>
  );
};

export default Router;
