import { FunctionComponent } from "react";
import { useAuth } from "../../contexts/auth.context";
import { FiFile, FiMenu, FiSettings } from "react-icons/fi";
import { IoIosCloseCircle } from "react-icons/io";
import { NavLink, Link } from "react-router-dom";

import { Box, Button, Icon, VStack, Text, HStack, useDisclosure, IconButton, Divider, Flex } from "@chakra-ui/react";
import { LogoutIcon, TMABigLogo } from "../Icons";
import { TMAMobileLogo } from "../Icons";
import { MdLocalHospital, MdMessage, MdOutlineDashboard } from "react-icons/md";
import { useTourContext } from "src/contexts/tour.context";

const navLinks = [
  {
    label: "Dashboard",
    href: "/",
    icon: () => <MdOutlineDashboard color="white" />,
    id: "dashboard-page",
    roles: ["patient", "doctor"],
  },
  {
    label: "My files",
    href: "/my-files",
    icon: () => <FiFile color="white" />,
    id: "my-files-page",
    roles: ["patient", "doctor"],
  },
  {
    label: "Medications",
    href: "/my-medications",
    icon: () => <MdLocalHospital color="white" />,
    id: "medications-page",
    roles: ["patient", "doctor"],
  },
  {
    label: "Settings",
    href: "/settings",
    icon: () => <FiSettings color="white" />,
    id: "settings-page",
    roles: ["patient"],
  },
  {
    label: "Chatbot",
    href: "/chatbot",
    icon: () => <MdMessage color="white" />,
    id: "chatbot-page",
    roles: ["patient", "doctor"],
  },
];

export const Sidebar: FunctionComponent = () => {
  const { signOut, user } = useAuth();
  const { setState, state } = useTourContext();

  return (
    <VStack
      width="200px"
      height="100vh"
      paddingTop="48px"
      paddingX="22px"
      color="white"
      background="linear-gradient(180deg,#234670,#496ca1)"
      justify="space-between"
    >
      <VStack spacing="16px">
        <Box marginBottom="64px">
          <Icon as={TMABigLogo} />
        </Box>
        {navLinks
          .filter(({ roles }) => roles.includes(user.role))
          .map((navLink) => (
            <NavItem key={navLink.label} id={navLink.id} text={navLink.label} href={navLink.href} icon={navLink.icon} />
          ))}
      </VStack>
      <Box>
        <Button
          colorScheme="yellow"
          onClick={() => {
            setState({ run: true });
          }}
        >
          {state.stepIndex > 0 ? "Continue tour" : "Usage Tutorial"}
        </Button>
        <LogoutButton onClick={signOut} />
      </Box>
    </VStack>
  );
};

export const MobileNavbar: FunctionComponent = () => {
  const { isOpen, onToggle } = useDisclosure();

  const { signOut, user } = useAuth();

  return (
    <Box
      as="nav"
      background="linear-gradient(180deg,#234670,#496ca1)"
      color="white"
      position="fixed"
      zIndex="20"
      width="100vw"
    >
      <Box as="nav" background="bg-surface" boxShadow="sm" paddingY="4" paddingX="6">
        <HStack justify="space-between">
          <Link to="/">
            <HStack>
              <TMAMobileLogo />
              <Text as="span" color="white">
                TMA
              </Text>
            </HStack>
          </Link>
          <IconButton
            variant="ghost"
            _focus={{
              background: "transparent",
            }}
            icon={isOpen ? <IoIosCloseCircle fontSize="2rem" /> : <FiMenu fontSize="2rem" />}
            aria-label="Open Menu"
            onClick={onToggle}
          />
        </HStack>
        {isOpen ? (
          <>
            <Divider />
            <Box marginTop="20px">
              <Flex direction="column" gap="16px" marginBottom="100px">
                {navLinks
                  .filter(({ roles }) => roles.includes(user.role))
                  .map((navLink) => (
                    <NavItem
                      key={navLink.label}
                      text={navLink.label}
                      href={navLink.href}
                      icon={navLink.icon}
                      onClick={onToggle}
                    />
                  ))}
              </Flex>
              <LogoutButton onClick={signOut} />
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

function NavItem({
  text,
  icon,
  href,
  onClick,
  id,
}: {
  text: string;
  icon: any;
  id?: string;
  href: string;
  onClick?: Function;
}) {
  return (
    <NavLink id={id} to={href} onClick={() => onClick?.()}>
      <HStack paddingLeft="8px" spacing="16px" width="156px" height="58px" cursor="pointer">
        <Box
          width="40px"
          height="40px"
          borderRadius="50%"
          display="grid"
          placeItems="center"
          background="linear-gradient(180deg,#234670,#496ca1)"
          boxShadow="4px 4px 20px rgba(73, 108, 161, 0.7)"
        >
          <Icon as={icon} />
        </Box>
        <Text as="span" fontWeight="600">
          {text}
        </Text>
      </HStack>
    </NavLink>
  );
}

function LogoutButton({ onClick }: { onClick: () => void }) {
  return (
    <Button
      background="transparent"
      marginBottom="24px"
      _hover={{
        background: "transparent",
        color: "gray.400",
      }}
      onClick={onClick}
    >
      <Icon as={LogoutIcon} />
      <Text as="span" marginLeft="8px">
        Log out
      </Text>
    </Button>
  );
}
