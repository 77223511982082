import React, { ChangeEvent, useRef } from "react";
import { Box, Button, Text } from "@chakra-ui/react";

interface FileUploadProps {
  onFileSelect: (file: File) => void;
  accept?: string;
  buttonText?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileSelect, accept = "", buttonText = "Upload" }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onFileSelect(file);
    }
  };

  return (
    <Box>
      <input
        type="file"
        ref={fileInputRef}
        accept={accept}
        onChange={handleFileInputChange}
        style={{ display: "none" }}
      />
      <Button onClick={() => fileInputRef.current?.click()} colorScheme="blue" size="md">
        {buttonText}
      </Button>
    </Box>
  );
};

export default FileUpload;
