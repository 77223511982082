import { FunctionComponent } from "react";
import MedicationsTimeline from "../../components/Medications/MedicationsTimeline";
import { Box, Heading, HStack } from "@chakra-ui/react";
import { useRareCare } from "../../contexts/rarecare.context";
import EmptyState from "../EmptyState";
// import Medications from "./Medications";

const MyMedications: FunctionComponent = () => {
  const { User, Case } = useRareCare();

  if (!User || !Case) {
    return null;
  }

  return (
    <Box background="white" padding="40px" borderRadius="8px" height={"500px"} width={"100%"} overflow="hidden">
      <HStack justify="space-between">
        <Heading color="grayOne" fontSize="20px" fontWeight="700">
          My Medications
        </Heading>
      </HStack>

      {Case.Medications?.length ? (
        <>
          <MedicationsTimeline meds={Case.Medications} />
          {/* <Medications data={Case.Medications} /> */}
        </>
      ) : (
        <EmptyState header="No data available" description="Sorry, there is no data available to show." />
      )}
    </Box>
  );
};

export default MyMedications;
