import { Box, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { MdSentimentDissatisfied } from "react-icons/md";

interface EmptyStateProps {
  header: string;
  description: string;
}

const EmptyState = ({ header, description }: EmptyStateProps) => {
  return (
    <Flex marginTop="40px" alignItems="center" justifyContent="center" flexDirection="column">
      <Icon as={MdSentimentDissatisfied} color="gray.500" boxSize="3rem" marginBottom="1rem" />
      <Box textAlign="center" width="100%">
        <Heading size="md" marginBottom="1rem" color="gray.500">
          {header}
        </Heading>
        <Text color="gray.500">{description}</Text>
      </Box>
    </Flex>
  );
};

export default EmptyState;
