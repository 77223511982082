import { FunctionComponent } from "react";
import AddFile from "./AddFile";
// import AddProvider from "./AddProvider";
import { Flex } from "@chakra-ui/react";
import AddMedicalRecord from "./AddMedicalRecord";
import DownloadReport from "./DownloadReport";

const CaseActions: FunctionComponent = () => {
  return (
    <>
      <Flex width="100%" flex="1" minWidth="300px" flexWrap="wrap" gap="16px">
        <AddMedicalRecord />
        <AddFile />
        {/* <AddProvider /> */}
        <DownloadReport />
      </Flex>
    </>
  );
};

export default CaseActions;
