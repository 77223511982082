import { Box } from "@chakra-ui/react";
import NavBar from "./NavBar";

const CareTeamLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <Box>
      <NavBar />
      <Box padding={["16px", "16px 100px"]}>{children}</Box>
    </Box>
  );
};

export default CareTeamLayout;
