import React from "react";
import { useAsyncDebounce } from "react-table";
import { Box, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export default function TableSearchInput({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  CustomSearchInputComponent,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return CustomSearchInputComponent ? (
    <CustomSearchInputComponent
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  ) : (
    <Box display="flex" alignItems="center" marginBottom="4">
      <Box position="relative" width="full">
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.500" />} paddingLeft="3" />
          <Input
            backgroundColor="gray.50"
            border="1px"
            borderColor="gray.300"
            color="gray.900"
            fontSize="sm"
            borderRadius="lg"
            focusBorderColor="blue.500"
            focusRingColor="blue.500"
            width="full"
            paddingLeft="10"
            paddingTop="2.5"
            paddingBottom="2.5"
            placeholder={`Search table: ${count} records...`}
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </InputGroup>
      </Box>
    </Box>
  );
}
