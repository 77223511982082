import axios, { AxiosResponse } from "axios";

const API_BASE_URL = process.env.REACT_APP_RARECARE_API || "https://api.rarecare.com";

export async function getCase(CaseID: string): Promise<RareCare.Case> {
  const endpoint = API_BASE_URL + "/case/get";
  return axios.post(endpoint, { CaseID }).then((response: AxiosResponse<RareCare.Case>) => {
    const data = response.data;
    if (!data.ICD10Codes) {
      data.ICD10Codes = {
        Diagnoses: [],
        Symptoms: [],
      };
    }
    if (!data.ICD10Codes.Diagnoses) data.ICD10Codes.Diagnoses = [];
    if (!data.ICD10Codes.Symptoms) data.ICD10Codes.Symptoms = [];
    return data;
  });
}
