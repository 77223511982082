import Layout from "src/components/Layout";
import { useRareCare } from "src/contexts/rarecare.context";
import Medications from "src/components/DashboardCards/Medications";
import { Heading } from "@chakra-ui/react";

export default function MyMedicationsPage() {
  const { Case } = useRareCare();

  return (
    <Layout>
      <Heading color="grayOne" fontSize="20px" fontWeight="700" marginBottom="16px">
        My Medications
      </Heading>
      <Medications data={Case?.Medications || []} />
    </Layout>
  );
}
