import React, { useCallback, useContext, useEffect, useReducer, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getCase } from "src/services/Case";
import { getPatient } from "src/services/Patient";
import { RareCareCase } from "../services/RareCare/RareCare";

export interface CaseContextType {
  User: RareCare.Patient | null;
  Case: RareCare.Case | null;
  RareCare: RareCareCase | null;
  loading: Boolean;
  refreshCase: Function;
  error: boolean;
  errorMessage: string | null;
  dispatch: React.Dispatch<ActionType>;
}

type ActionType = {
  type: "initialize" | "setCase" | "error" | "setTimepoint" | "setTimelineItem" | "setUser";
  payload: Partial<Omit<CaseContextType, "dispatch" | "refreshCase">> & {
    type?: string;
    date?: string;
    name?: string;
    description?: string;
  };
};

const CaseContext = React.createContext<CaseContextType | undefined>(undefined);

const initialState: CaseContextType = {
  User: null,
  Case: null,
  RareCare: null,
  loading: true,
  error: false,
  errorMessage: null,
  refreshCase: () => {},
  dispatch: () => {},
};

function reducer(state: CaseContextType, action: ActionType): CaseContextType {
  switch (action.type) {
    case "initialize": {
      const data = action.payload;
      return { ...state, ...data };
    }

    case "setUser": {
      const { User } = action.payload;
      return { ...state, User: { ...state.User, ...User } as CaseContextType["User"] };
    }

    case "setCase": {
      const { Case } = action.payload;
      return { ...state, Case: Case as CaseContextType["Case"] };
    }

    case "setTimelineItem": {
      const { payload } = action;
      const { date } = payload;
      const timeline = state.Case?.Timeline || {};
      const newTimelineItem = {
        [date as string]: {
          Diagnostics: {},
          Procedures: {},
          Symptoms: {},
        },
      };
      return {
        ...state,
        Case: {
          ...state.Case,
          Timeline: {
            ...newTimelineItem,
            ...timeline,
          },
        } as CaseContextType["Case"],
      };
    }

    case "setTimepoint": {
      const { payload } = action;
      const { type, date, name, description } = payload;
      const timeline = structuredClone(state.Case?.Timeline || {});
      const timelineItem = timeline[date as string];
      timelineItem[type as string][name as string] = description;
      return {
        ...state,
        Case: {
          ...state.Case,
          Timeline: timeline,
        } as CaseContextType["Case"],
      };
    }

    case "error": {
      const { payload } = action;
      return { ...state, ...payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const useCase = () => {
  const context = useContext(CaseContext);

  if (context === undefined) {
    throw new Error("useCase must be used within a CaseProvider");
  }

  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const patientUserId = searchParams.get("patient");

  const { dispatch } = context;

  const refreshCase = useCallback(() => {
    if (!context.Case) return;
    getCase(context.Case.CaseID).then((result) => {
      dispatch({
        type: "setCase",
        payload: {
          Case: result,
        },
      });
    });
  }, [context.Case, dispatch]);

  useEffect(() => {
    if (!patientUserId || context.Case) return;

    const fetchCase = async () => {
      try {
        setLoading(true);
        const patient = await getPatient(patientUserId);
        const caseData = await getCase(patient.CaseID);

        // const patientAvatar = getPatientAvatar(patient);
        // patient.avatarUrl = patientAvatar

        dispatch({
          type: "initialize",
          payload: {
            User: patient,
            Case: caseData,
            loading: false,
          },
        });
      } catch (error: any) {
        console.error(error);
        dispatch({
          type: "error",
          payload: {
            error: true,
            errorMessage: error.message,
          },
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCase();
  }, [context.Case, dispatch, patientUserId]);

  return {
    ...context,
    loading,
    refreshCase,
  };
};

const CaseProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { ...state, dispatch };
  return <CaseContext.Provider value={value}>{props.children}</CaseContext.Provider>;
};

export { CaseProvider, useCase };
