import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListIcon,
  ListItem,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { on } from "events";
import { ChangeEvent, useState } from "react";
import { FiEye } from "react-icons/fi";
import { MdDashboard, MdHistory, MdLocalHospital } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/contexts/auth.context";
import { useRareCare } from "src/contexts/rarecare.context";
import { LabelItemObject, LabelsObject } from "src/types/file-labels.types";
import { debounce } from "src/utils";
import FileExplorerDrawer from "../Files/FileExplorerDrawer";
import Loader from "../Loader";

export default function AppSearch() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenHistory, onOpen: onOpenHistory, onClose: onCloseHistory } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [historyIsLoading, setHistoryIsLoading] = useState(false);
  const [result, setResult] = useState<any>(null);
  const [fileName, setFileName] = useState("");
  const [initialPage, setInitialPage] = useState(1);
  const [labels, setLabels] = useState<LabelsObject | null>(null);
  const [history, setHistory] = useState<any[]>([]);
  const navigate = useNavigate();

  const {
    user: { role },
  } = useAuth();
  const { RareCare } = useRareCare();

  const hasSearchResult = Object.values(result || {})
    .flat()
    .filter(Boolean).length;

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value?.trim();
    if (!searchTerm) return setResult(null);

    try {
      if (searchTerm.length >= 3) {
        setIsLoading(true);
        setResult(null);
        const response = await RareCare?.searchCase(searchTerm);
        setResult(response.Payload);
      }
    } catch (error: any) {
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchHistory = async () => {
    try {
      onOpenHistory();
      setHistoryIsLoading(true);
      setHistory([]);
      const response = await RareCare?.getSearchHistory();
      setHistory(response.Payload);
    } catch (error: any) {
      alert(error.message);
      onCloseHistory();
    } finally {
      setHistoryIsLoading(false);
    }
  };

  return (
    <>
      <Flex justifyContent="flex-end" gap="8px">
        {role === "doctor" && (
          <Button colorScheme="red" leftIcon={<MdLocalHospital />} onClick={() => navigate("/care-team-dashboard")}>
            Care team dashboard
          </Button>
        )}

        <Button colorScheme="blue" variant="outline" leftIcon={<MdHistory />} onClick={handleSearchHistory}>
          Search history
        </Button>
      </Flex>
      <Box position="relative" alignItems="center" width="100%">
        <Box position="relative" width="full" zIndex={21}>
          <InputGroup gap="10px">
            <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.500" />} paddingLeft="3" />
            <Input
              boxShadow="0px 0px 10px rgba(35, 70, 112, 0.03)"
              borderRadius="100px"
              backgroundColor="white"
              color="gray.900"
              fontSize="sm"
              focusBorderColor="blue.500"
              width="full"
              paddingLeft="10"
              paddingTop="2.5"
              paddingBottom="2.5"
              placeholder={`Search files, medications, timeline...`}
              onChange={debounce(handleSearch, 500)}
            />
          </InputGroup>
          {isLoading ? <Loader /> : null}
        </Box>

        {result && !hasSearchResult ? <Text>Nothing matches your search term.</Text> : null}

        {hasSearchResult ? (
          <Box
            background="white"
            boxShadow="0px 0px 10px rgba(35, 70, 112, 0.03)"
            border="1px solid"
            borderBottomRadius="20px"
            borderColor="inherit"
            zIndex={10}
            width="100%"
            minHeight="50vh"
            maxHeight="60vh"
            overflow="scroll"
            position="absolute"
            padding="30px"
            top="25px"
          >
            <Button
              size="xs"
              onClick={() => {
                setResult(null);
              }}
            >
              Close
            </Button>
            <Flex direction={["column", "row"]} gap="20px">
              {result?.Files?.length ? (
                <VStack align="left" width={["100%", "30%"]} padding="4px">
                  <Text color="gray.500" fontWeight="bold">
                    Files
                  </Text>
                  {result.Files.map((file: { filename: string; labels: LabelItemObject[] }) => {
                    return file.labels.map((label: LabelItemObject, index) => {
                      return (
                        <VStack key={index} align="left" gap="20px">
                          <Box>
                            <Heading
                              size="sm"
                              color="blue.700"
                              as="h3"
                              role="button"
                              cursor="pointer"
                              _hover={{ textDecoration: "underline", color: "blue.600" }}
                              onClick={() => {
                                setFileName(file.filename);
                                setLabels({ medications: file.labels, diagnosis: [], diseases: [] });
                                setInitialPage(label.Page);
                                onOpen();
                              }}
                            >
                              {label.Text}
                            </Heading>
                            <Text color="gray.500">Filename: {file.filename}</Text>
                            <Text color="gray.500">Page: {label.Page}</Text>
                          </Box>
                        </VStack>
                      );
                    });
                  })}
                </VStack>
              ) : null}

              {/* <VStack align="left" width={["100%", "30%"]} padding="4px">
                <Text color="gray.500" fontWeight="bold">
                  Timeline events
                </Text>
                <VStack align="left" gap="20px">
                  <Box>
                    <Heading size="sm" color="blue.700" as="h3" cursor="pointer">
                      This is the text that matches the search
                    </Heading>
                    <Text color="gray.500">Date: 12/02/2023</Text>
                  </Box>
                </VStack>
              </VStack>

              <VStack align="left" width={["100%", "30%"]} padding="4px">
                <Text color="gray.500" fontWeight="bold">
                  Medications
                </Text>
                <VStack align="left" gap="20px">
                  <Box>
                    <Heading size="sm" color="blue.700" as="h3">
                      The medication name
                    </Heading>
                    <Text color="gray.500">Dosage: 500mg</Text>
                  </Box>
                </VStack>
              </VStack> */}
            </Flex>
          </Box>
        ) : null}

        {isOpen && (
          <FileExplorerDrawer
            key={fileName}
            fileName={fileName}
            initialPage={initialPage}
            isOpen={isOpen}
            onClose={onClose}
            fileLabels={labels}
          />
        )}

        {isOpenHistory && (
          <Drawer isOpen={isOpenHistory} placement="right" onClose={onCloseHistory}>
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Search history</DrawerHeader>

              <DrawerBody>
                {historyIsLoading ? <Loader /> : null}
                <List spacing={3}>
                  {history?.map((item, index) => (
                    <ListItem
                      key={index}
                      background={`blue.100`}
                      color={`blue.800`}
                      padding="4px"
                      borderRadius="4px"
                      cursor="pointer"
                      _hover={{ background: `blue.200` }}
                      onClick={() => {
                        setResult(item.Result);
                        onCloseHistory();
                      }}
                    >
                      <HStack height="100%">
                        <Text flex="1" maxWidth="100%" wordBreak="break-word">
                          {item.Keywords?.join("")}
                        </Text>
                        <ListIcon as={FiEye} color={`blue.700`} />
                      </HStack>
                    </ListItem>
                  ))}
                </List>
              </DrawerBody>

              <DrawerFooter>
                <Button variant="outline" mr={3} onClick={onCloseHistory}>
                  Cancel
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        )}
      </Box>
    </>
  );
}
