import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ChangeEvent, useMemo, useState } from "react";

import Loader from "src/components/Loader";
import Select from "src/components/Select";
import { bloodTypes } from "src/Constants/common";
import { useRareCare } from "src/contexts/rarecare.context";
import { centimeterToFeet, feetToCentimeter, gramToLibs, LibsToGram, roundToTwoDecimal } from "src/utils";

interface VitalsDrawerProps {
  initialValue: RareCare.CaseVital | null;
  isOpen: boolean;
  onClose: () => void;
}

export default function VitalsEditDrawer({ initialValue, isOpen, onClose: closeDrawer }: VitalsDrawerProps) {
  const [updating, setUpdating] = useState(false);
  const { RareCare, Case, dispatch } = useRareCare();
  const toast = useToast({ position: "top-right" });

  const initialWeightValue = initialValue ? gramToLibs(initialValue.Weight) : "";
  const initialHeightValue = initialValue ? centimeterToFeet(initialValue.Height) : "";
  const initialBloodTypeOption = initialValue
    ? { label: bloodTypes[initialValue.BloodType], value: initialValue.BloodType }
    : null;

  const blodTypeOptions = useMemo(() => {
    return bloodTypes.map((type, index) => ({ label: type, value: index }));
  }, []);

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    if (!RareCare) return;

    event.preventDefault();

    const formData = new FormData(event.target);
    const formDataObject = Object.fromEntries(formData.entries());

    const bloodType = Number(formDataObject.bloodType);
    const heightInFeet = roundToTwoDecimal(Number(formDataObject.heightInFeet));
    const weightInLibs = roundToTwoDecimal(Number(formDataObject.weightInLibs));

    const vitals = {
      BloodType: bloodType,
      Height: Math.round(feetToCentimeter(heightInFeet)),
      Weight: Math.round(LibsToGram(weightInLibs)),
    };

    setUpdating(true);
    const { error, message, data } = await RareCare.updateCaseVitals(vitals);
    setUpdating(false);

    if (error || data.Error) {
      toast({ status: "error", description: message });
    } else {
      closeDrawer();
      toast({ status: "success", description: message });
      dispatch({
        type: "setCase",
        payload: {
          Case: { ...Case, Vitals: vitals } as RareCare.Case,
        },
      });
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={closeDrawer} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit vitals</DrawerHeader>

        {updating ? <Loader /> : null}

        <form onSubmit={handleSubmit}>
          <DrawerBody>
            <VStack gap="16px" mb="50px">
              <FormControl>
                <FormLabel>Blood type *</FormLabel>
                <Select
                  name="bloodType"
                  defaultValue={initialBloodTypeOption}
                  options={blodTypeOptions}
                  required
                  placeholder="Select blood type.."
                  isDisabled={updating}
                />
                <FormHelperText color="brand.gray2">
                  <em>Select your blood type.</em>
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Weight (in lbs)*</FormLabel>
                <Input
                  name="weightInLibs"
                  type="number"
                  step="any"
                  defaultValue={initialWeightValue}
                  required
                  min={2.2}
                  max={1653.47}
                  placeholder="Type your weight here..."
                  disabled={updating}
                />
                <FormHelperText color="brand.gray2">
                  <em>Enter your weight in Lbs. i.e.: 297.5</em>
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Height (in fts)*</FormLabel>
                <Input
                  name="heightInFeet"
                  type="number"
                  step="any"
                  defaultValue={initialHeightValue}
                  required
                  min={0.32}
                  max={9.9}
                  placeholder="Type your height here..."
                  disabled={updating}
                />
                <FormHelperText color="brand.gray2">
                  <em>Enter your weight in fts. i.e.: 5.5</em>
                </FormHelperText>
              </FormControl>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={closeDrawer} disabled={updating}>
              Cancel
            </Button>
            <Button type="submit" colorScheme="blue" disabled={updating}>
              Save
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
}
