import { ChangeEvent, useEffect, useState } from "react";

import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import FileExplorerDrawer from "src/components/Files/FileExplorerDrawer";
import { KebabMenuIcon, PDFIcon } from "src/components/Icons";
import Layout from "src/components/Layout";
import Loader from "src/components/Loader";
import { useRareCare } from "src/contexts/rarecare.context";
import { convertBytesToKBorMB, debounce, downloadFile } from "src/utils";

interface CaseFile {
  Filename: string;
  Size: number;
}

export default function MyFiles() {
  const [files, setFiles] = useState<CaseFile[]>([]);
  const [filteredFiles, setFilteredFiles] = useState<CaseFile[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { RareCare } = useRareCare();

  const handleSearchFiles = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const fileName = value.trim().toLowerCase();
    if (!fileName) return setFilteredFiles(files);

    const matchedFiles = files.filter((file) => {
      return file.Filename.toLowerCase().includes(fileName);
    });

    setFilteredFiles(matchedFiles);
  };

  useEffect(() => {
    const getFiles = async () => {
      try {
        const files = await RareCare?.getCaseFileList();
        setFiles(files || []);
        setFilteredFiles(files || []);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getFiles();
  }, [RareCare]);

  return (
    <Layout>
      <Heading color="grayOne" fontSize="20px" fontWeight="700" marginBottom="16px">
        My files
      </Heading>
      <Box display="flex" alignItems="center" marginBottom="4" marginTop="4" width={["100%", "100%", "100%", "50%"]}>
        <Box position="relative" width="full">
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.500" />} paddingLeft="3" />
            <Input
              isDisabled={isLoading}
              boxShadow="0px 0px 10px rgba(35, 70, 112, 0.03)"
              borderRadius="100px"
              backgroundColor="white"
              color="gray.900"
              fontSize="sm"
              focusBorderColor="blue.500"
              width="full"
              paddingLeft="10"
              paddingTop="2.5"
              paddingBottom="2.5"
              placeholder={`Search files...`}
              onChange={debounce(handleSearchFiles, 300)}
            />
          </InputGroup>
        </Box>
      </Box>

      {isLoading && <Loader />}

      <Flex direction={["column", "row"]} flexWrap="wrap" gap="20px" alignItems="center">
        {filteredFiles.map((file, index) => (
          <FileCard key={index} file={file} />
        ))}
      </Flex>
    </Layout>
  );
}

function FileCard({ file }: { file: CaseFile }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { RareCare } = useRareCare();

  const handleDownloadFile = () => {
    const fileName = file.Filename;
    RareCare?.downloadFile(fileName).then((data) => {
      downloadFile({ fileName, data });
    });
  };

  const handlePreviewFile = async () => {
    try {
      const fileName = file.Filename;
      const base64File = await RareCare?.downloadFile(fileName);
      if (!base64File) {
        throw new Error(`Could not preview file ${fileName}`);
      }
      const fileBlob = new Blob([base64File], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(fileBlob);

      window.open(fileURL, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Box
        minWidth={["100%", "270px"]}
        maxWidth={["100%", "360px"]}
        height="256px"
        boxShadow="0px 0px 10px rgba(35, 70, 112, 0.03)"
        borderRadius="8px"
        background="white"
        flex="1"
      >
        <VStack padding="20px" paddingTop="40px">
          <PDFIcon />
          <Text as="span" maxWidth="100%" color="brand.blue" fontWeight="600" isTruncated>
            {file.Filename}
          </Text>
        </VStack>
        <HStack
          align="left"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          borderTop="2px solid #F7F9FD"
          padding="5px 20px"
        >
          <Text color="#373B4A" fontWeight="400">
            File size:{" "}
            <Text as="span" color="brand.blue" fontWeight="700">
              {convertBytesToKBorMB(file.Size)}
            </Text>
          </Text>
          <Menu>
            <MenuButton as={IconButton} variant="ghost" icon={<KebabMenuIcon />} />
            <MenuList>
              <MenuItem onClick={onOpen}>Explore</MenuItem>
              <MenuItem onClick={() => handlePreviewFile()}>Preview</MenuItem>
              <MenuItem onClick={() => handleDownloadFile()}>Download</MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Box>
      {isOpen ? (
        <FileExplorerDrawer key={file.Filename} fileName={file.Filename} isOpen={isOpen} onClose={onClose} />
      ) : null}
    </>
  );
}
