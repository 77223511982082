import axios from "axios";

const APIEndpoint = process.env.REACT_APP_RARECARE_API || "https://api.rarecare.com";

export async function getMedicalProfessional(userId: string) {
  try {
    const endpoint = APIEndpoint + "/medical_professional/get";
    const { data }: any = await axios.post(endpoint, {
      UserId: userId,
    });
    return { data: data.Payload };
  } catch (error: any) {
    console.error(error);
    return { error, message: error.message, data: null };
  }
}

export async function getPatients(userId: string) {
  try {
    const endpoint = APIEndpoint + "/medical_professional/patients/list";
    const { data }: any = await axios.post(endpoint, {
      UserId: userId,
    });
    return { data: data.Payload };
  } catch (error: any) {
    console.error(error);
    return { error, message: error.message, data: null };
  }
}
