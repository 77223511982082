import React, { ChangeEvent } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Input,
  Stack,
  Text,
  useToast,
  FormControl,
} from "@chakra-ui/react";
import { useAuth } from "../contexts/auth.context";
import Layout from "../components/Layout";
import { RCContainer } from "../UIElements/RCContainer";
import FileUpload from "../components/FileUpload";
import { useRareCare } from "../contexts/rarecare.context";
import AddressForm, { AddressInfo } from "../components/AddressForm";
import { fileToBase64 } from "../utils";

const initialAddress: AddressInfo = {
  streetAddress: "",
  apartmentOrSuite: "",
  country: "",
  state: "",
  city: "",
  zipOrPostalCode: "",
};

const Settings: React.FC = () => {
  const { user, updateProfile } = useAuth();
  const { RareCare, dispatch } = useRareCare();
  const [name, setName] = React.useState(user.name);
  const [familyName, setFamilyName] = React.useState(user.familyName);
  const [email, setEmail] = React.useState(user.email);
  const [birthdate, setBirthdate] = React.useState(user.birthdate);
  const [phoneNumber, setPhoneNumber] = React.useState(user.phoneNumber || "");
  const [address, setAddress] = React.useState<AddressInfo | "">("");
  const [updating, setUpdating] = React.useState(false);

  const toast = useToast();
  const loading = updating;

  if (user.role !== "patient") {
    return <h1>Not authorized to see this page</h1>;
  }

  if (user.address && !address) {
    try {
      const userAddress = JSON.parse(user.address);
      setAddress(userAddress);
    } catch (error) {
      setAddress(initialAddress);
    }
  }

  const onUpdateProfile = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setUpdating(true);
    updateProfile({
      name,
      familyName,
      email,
      birthdate,
      phoneNumber: formatPhone(phoneNumber),
      address: JSON.stringify(address),
    })
      .then(({ success, message }) => {
        if (success) {
          toast({
            position: "top-right",
            status: "success",
            title: "Profile successfully updated!",
          });
        } else {
          toast({
            position: "top-right",
            status: "error",
            title: message || "Update Profile Failed",
          });
        }
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const handleFileSelect = (file: File) => {
    fileToBase64(file).then((base64: string) => {
      RareCare?.uploadAvatar(base64)
        .then(() => {
          dispatch({
            type: "setUser",
            payload: {
              User: { ...user, avatarUrl: base64 },
            },
          });
          toast({
            position: "top-right",
            status: "success",
            title: "Avatar uploaded correctly",
          });
        })
        .catch((err) => {
          toast({
            position: "top-right",
            status: "error",
            title: err?.message || "File upload failed",
          });
        });
    });
  };

  return (
    <Layout>
      <RCContainer py={{ base: "4", md: "8" }} maxW={"800"} id="settings-form">
        <Stack spacing="5" p={10}>
          <Box>
            <Text fontSize="lg" fontWeight="medium">
              {name + " " + familyName}
            </Text>
          </Box>
          <Divider />
          <Stack spacing={{ base: "3", md: "5" }} width="full" maxW={{ md: "3xl" }}>
            <Avatar size="xl" name={name + " " + familyName} src={user?.avatarUrl} />
            <FileUpload
              onFileSelect={handleFileSelect}
              accept="image/png, image/gif, image/jpeg"
              buttonText="Upload Image"
            />
          </Stack>
          <Divider />
          <form onSubmit={onUpdateProfile}>
            <Flex direction="column" gap="6" margin="0 auto" p={4}>
              <Flex gap="4" direction={["column", "column", "row"]}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    disabled={loading}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Family Name</FormLabel>
                  <Input
                    type="text"
                    disabled={loading}
                    value={familyName}
                    onChange={(e) => setFamilyName(e.target.value)}
                    required
                  />
                </FormControl>
              </Flex>

              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  disabled={loading}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormControl>

              <FormControl>
                <FormLabel>Birth Date</FormLabel>
                <Input
                  disabled={loading}
                  placeholder="Select birth date"
                  size="md"
                  type="date"
                  value={birthdate}
                  onChange={(e) => setBirthdate(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  disabled={loading}
                  placeholder="+15545855955"
                  size="md"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </FormControl>

              <AddressForm
                disabled={loading}
                addressInfo={address as AddressInfo}
                onChange={(addressObj: AddressInfo) => setAddress(addressObj)}
              />
            </Flex>

            <Stack>
              <Flex direction="row-reverse">
                <Button type="submit" colorScheme="blue" disabled={loading}>
                  {updating ? "Updating..." : "Update Profile"}
                </Button>
              </Flex>
            </Stack>
          </form>
        </Stack>
      </RCContainer>
    </Layout>
  );
};

function formatPhone(phone: string) {
  if (!phone.length) return "";
  if (!phone.startsWith("+")) return `+${phone}`;
  return phone;
}

export default Settings;
