import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerCloseButton,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { OpenFDA } from "../../services/RareCare/OpenFDA";

type Props = {
  DrugName: string;
  onOpen: () => void;
  isOpen: boolean;
  onClose: () => void;
};

const MedicationDrawer: React.FC<Props> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [drugInfo, setDrugInfo] = useState<any>(null);

  useEffect(() => {
    const openFDA = new OpenFDA();
    if (props.DrugName !== "") {
      openFDA.SearchName(props.DrugName).then((data: any) => {
        setDrugInfo(data);
        setLoaded(true);
      });
    }
  }, [props.DrugName]);

  // if (!loaded && (props.DrugName !== "")) {
  //   const openFDA = new OpenFDA();
  //
  //   openFDA.SearchName(props.DrugName).then((data: any) => {
  //     setDrugInfo(data)
  //     setLoaded(true)
  //   })
  // }

  return (
    <>
      <Drawer isOpen={props.isOpen} placement="right" onClose={props.onClose} size={"lg"}>
        <DrawerOverlay />
        {loaded && (
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>{props.DrugName}</DrawerHeader>

            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Th>Brand Name</Th>
                  <Td>{drugInfo.brandName}</Td>
                </Tr>
                <Tr>
                  <Th>Generic Name</Th>
                  <Td>{drugInfo.genericName}</Td>
                </Tr>
                <Tr>
                  <Th>Manufacturer Name</Th>
                  <Td>{drugInfo.manufacturerName}</Td>
                </Tr>
                <Tr>
                  <Th>Pharm Class EPC</Th>
                  <Td>{drugInfo.pharmClassEPC}</Td>
                </Tr>
                <Tr>
                  <Th>Pharm Class PE</Th>
                  <Td>{drugInfo.pharmClassPE}</Td>
                </Tr>
                <Tr>
                  <Th>Route</Th>
                  <Td>{drugInfo.route}</Td>
                </Tr>
              </Tbody>
            </Table>

            <DrawerBody></DrawerBody>
          </DrawerContent>
        )}
      </Drawer>
    </>
  );
};

export default MedicationDrawer;
