import { CalendarIcon, KebabMenuIcon } from "../../Icons";
import {
  HStack,
  Text,
  Icon,
  Flex,
  IconButton,
  VStack,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  DrawerFooter,
  Button,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import Select from "src/components/Select";
import Loader from "src/components/Loader";
import { ChangeEvent, useState } from "react";
import { useRareCare } from "src/contexts/rarecare.context";

interface Props {
  date: string;
  color: string;
  onAddTimepoint: () => void;
}

export default function TimelineItem({ color, date, onAddTimepoint }: Props) {
  const dateTokens = date.split("/");
  const [day, month, year] = dateTokens;
  const dateObject = new Date(Number(year), Number(month), Number(day));
  const monthName = dateObject.toLocaleString("default", { month: "short" });

  return (
    <HStack>
      <Flex direction="column" gap="" color="brand.blue">
        <Text margin="0" fontSize="12px">
          {monthName.toUpperCase()}
        </Text>
        <Text as="strong" fontSize="20px">
          {day}
        </Text>
      </Flex>

      <HStack
        align={"left"}
        height="56px"
        width="100%"
        padding={["10px", "20px"]}
        borderLeft={`6px solid var(--chakra-colors-brand-light${color})`}
        borderRadius="8px"
        background={`brand.pale${color}`}
      >
        <HStack flex="1">
          <Icon as={() => <CalendarIcon fill={`var(--chakra-colors-brand-light${color})`} />} />
          <Text color="brand.darkblue" maxWidth={["130px", "200px"]} isTruncated>
            {dateObject.toDateString()}
          </Text>
        </HStack>

        <HStack>
          <Menu>
            <MenuButton as={IconButton} variant="ghost" icon={<KebabMenuIcon />} onClick={(e) => e.stopPropagation()} />
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onAddTimepoint();
                }}
              >
                Add timepoint
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </HStack>
    </HStack>
  );
}

function TimePoint({ title, description }: any) {
  return (
    <HStack width="100%">
      <Flex direction="column" color="#496CA1"></Flex>

      <HStack
        height="fit-content"
        width="100%"
        padding="20px"
        justify="space-between"
        borderLeft={`6px solid var(--chakra-colors-brand-lightyellow)`}
        borderRadius="8px"
        background={`brand.paleyellow`}
      >
        <VStack align="left" maxWidth="100%">
          <Text as="span" fontWeight="500" color="brand.darkblue">
            {title}
          </Text>
          <Text as="span" wordBreak="break-all">
            {description}
          </Text>
        </VStack>
      </HStack>
    </HStack>
  );
}

export function TimelineItemAccordion({ data }: any) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { procedures, symptoms, diagnostics } = data;
  const totalTimepoints = procedures.length + symptoms.length + diagnostics.length;

  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          <TimelineItem date={data.date} color="blue" onAddTimepoint={onOpen} />
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel
        pb={4}
        display="flex"
        flexDirection="column"
        gap="4"
        margin={["0 10px", "0 40px"]}
        marginBottom="50px"
      >
        {data.symptoms.length ? (
          <Box>
            <Text color="#234670" mb="2" fontWeight="bold">
              Symptoms
            </Text>
            <VStack>
              {data.symptoms.map((symptom: any, index: number) => (
                <TimePoint key={index} title={symptom.name} description={symptom.description} />
              ))}
            </VStack>
          </Box>
        ) : null}

        {data.diagnostics.length ? (
          <Box>
            <Text color="#234670" mb="2" fontWeight="bold">
              Diagnostics
            </Text>
            <VStack>
              {data.diagnostics.map((diagnostic: any, index: number) => (
                <TimePoint key={index} title={diagnostic.name} description={diagnostic.description} />
              ))}
            </VStack>
          </Box>
        ) : null}

        {data.procedures.length ? (
          <Box>
            <Text color="#234670" mb="2" fontWeight="bold">
              Procedures
            </Text>
            <VStack>
              {data.procedures.map((procedure: any, index: number) => (
                <TimePoint key={index} title={procedure.name} description={procedure.description} />
              ))}
            </VStack>
          </Box>
        ) : null}

        {totalTimepoints !== 0 ? (
          <Button variant="link" marginBottom="6" onClick={onOpen}>
            Click here to add a Timepoint
          </Button>
        ) : null}

        {totalTimepoints === 0 ? (
          <Button variant="link" onClick={onOpen}>
            No data to show here. Click to add Timepoint
          </Button>
        ) : null}
      </AccordionPanel>
      {isOpen && <AddTimepointDrawer isOpen={isOpen} onClose={onClose} itemDate={data.date} />}
    </AccordionItem>
  );
}

interface DrawerProps {
  isOpen: boolean;
  itemDate: string;
  onClose: () => void;
}

function AddTimepointDrawer({ itemDate, isOpen, onClose: closeDrawer }: DrawerProps) {
  const [updating, setUpdating] = useState(false);
  const { RareCare, dispatch } = useRareCare();
  const toast = useToast({ position: "top-right" });

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    if (!RareCare) return;

    event.preventDefault();

    const formData = new FormData(event.target);
    const formDataObject = Object.fromEntries(formData.entries());

    const itemType = formDataObject.type as Timeline.ItemType;
    const itemName = formDataObject.name as string;
    const itemDescription = formDataObject.description as string;

    setUpdating(true);
    const { error, message, data } = await RareCare.addTimelineTimepoint({
      type: itemType,
      date: itemDate,
      name: itemName,
      description: itemDescription || "",
    });
    setUpdating(false);

    if (error || data.Error) {
      toast({ status: "error", description: message });
    } else {
      closeDrawer();
      setUpdating(false);
      toast({ status: "success", description: message });
      dispatch({
        type: "setTimepoint",
        payload: {
          type: itemType,
          date: itemDate,
          name: itemName,
          description: itemDescription || "",
        },
      });
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={closeDrawer} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add timepoint</DrawerHeader>

        {updating ? <Loader /> : null}

        <form onSubmit={handleSubmit}>
          <DrawerBody>
            <VStack gap="16px">
              <HStack align="left">
                <Text as="strong">Timeline item Date: </Text>
                <Text>{itemDate}</Text>
              </HStack>

              <FormControl>
                <FormLabel>Type *</FormLabel>
                <Select
                  name="type"
                  options={[
                    { label: "Diagnostic", value: "Diagnostics" },
                    { label: "Procedure", value: "Procedures" },
                    { label: "Symptom", value: "Symptoms" },
                  ]}
                  required
                  placeholder="Select type.."
                  isDisabled={updating}
                />
                <FormHelperText color="brand.gray2">
                  <em>Select the type of the timeline item.</em>
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Name *</FormLabel>
                <Input
                  name="name"
                  type="text"
                  required
                  minLength={3}
                  maxLength={255}
                  placeholder="Type your weight here..."
                  disabled={updating}
                />
                <FormHelperText color="brand.gray2">
                  <em>Name of the timeline item</em>
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Description *</FormLabel>
                <Input
                  name="description"
                  type="text"
                  required
                  minLength={3}
                  maxLength={500}
                  placeholder="Type your height here..."
                  disabled={updating}
                />
                <FormHelperText color="brand.gray2">
                  <em>Description of the timeline item</em>
                </FormHelperText>
              </FormControl>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={closeDrawer} disabled={updating}>
              Cancel
            </Button>
            <Button type="submit" colorScheme="blue" disabled={updating}>
              Save
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
}
