import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import FileUpload from "../../FileUpload";
import { useRareCare } from "../../../contexts/rarecare.context";
import ActionButton from "./ActionButton";
import { fileToBase64 } from "../../../utils";

const AddFileDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { RareCare } = useRareCare();
  const toast = useToast({ position: "top-right" });

  const handleFileSelect = (file: File) => {
    fileToBase64(file).then((base64: string) => {
      RareCare?.uploadFile(file.name, base64)
        .then(() => {
          toast({
            status: "success",
            description: "File uploaded correctly",
          });
        })
        .catch(() => {
          toast({
            status: "error",
            description: "Error! Failed to upload file.",
          });
        });
    });
  };

  return (
    <>
      <ActionButton onClick={() => onOpen()} title={"Add File"} logoSrc={"../frame-411.svg"} />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"lg"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Upload A File</DrawerHeader>

          <DrawerBody>
            <FileUpload onFileSelect={handleFileSelect} accept="*" buttonText="Select a file" />
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="facebook">Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddFileDrawer;
