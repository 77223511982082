import { Button, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { set } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CareTeamLayout from "src/components/CareTeamLayout";
import Loader from "src/components/Loader";
import AppTable from "src/components/Table";
import { useAuth } from "src/contexts/auth.context";
import { useRareCare } from "src/contexts/rarecare.context";
import { getPatients } from "src/services/CareTeam";
import { getCase } from "src/services/Case";

const CareTeamDashboard = () => {
  const [patients, setPatients] = useState<RareCare.Case[] | []>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth();
  const { dispatch } = useRareCare();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await getPatients(user.username);
        if (error) return alert("Error fetching patients");
        setPatients(data);
      } catch (error) {
        alert("Error fetching patients");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const columns = [
    {
      Header: "Full name",
      Cell: ({ row }: any) => {
        const { Name, FamilyName, Email, CaseID } = row.original;
        return (
          <Button
            variant="link"
            onClick={async () => {
              try {
                setLoading(true);
                const data = await getCase(CaseID);
                const user = {
                  username: "",
                  name: Name,
                  familyName: FamilyName,
                  email: Email,
                  role: "patient",
                  picture: "",
                };
                dispatch({ type: "setCase", payload: { Case: data } });
                dispatch({ type: "setUser", payload: { User: user } });
                navigate("/");
              } catch (error) {
                alert("Error fetching case");
              } finally {
                setLoading(false);
              }
            }}
          >{`${Name} ${FamilyName}`}</Button>
        );
      },
    },
    {
      Header: "Email",
      accessor: "Email",
    },
    {
      Header: "Gender",
      accessor: "Gender",
    },
    {
      Header: "Diagnosis",
      accessor: "MainDiagnostic",
    },
    {
      Header: "Ethnicity",
      accessor: "Ethnicity",
    },
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <CareTeamLayout>
      <Heading as="h1" fontSize="20px" color="brand.gray1" mb="4">
        Your cases
      </Heading>
      <AppTable columns={columns} data={patients} />
    </CareTeamLayout>
  );
};

export default CareTeamDashboard;
