import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Stack,
  Button,
  Heading,
  Text,
  Link,
  Image,
  Checkbox,
  Spinner,
  Container,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import Select from "src/components/Select";
import VirtualizedSelect from "src/components/VirtualizedSelect";
import { signUpPatient } from "src/services/Patient";
import diagnosisJson from "src/utils/data/diagnosis.data.json";

const diagnosisOptions = diagnosisJson
  .map((v) => ({ label: v.label, value: v.label }))
  .sort((a, b) => a.label.localeCompare(b.label));

const fetchCountries = async () => {
  try {
    const url = "https://countriesnow.space/api/v0.1/countries";
    const response = await axios.get(url);
    const responseData = response.data;
    const data = responseData.data;
    return data;
  } catch (error) {
    throw error;
  }
};

const ethnicities = [
  { label: "African-American", value: "African-American" },
  { label: "Asian", value: "Asian" },
  { label: "Caucasian", value: "Caucasian" },
  { label: "Hispanic", value: "Hispanic" },
  { label: "Native American", value: "Native American" },
  { label: "Abazin", value: "Abazin" },
  { label: "Abkhazian", value: "Abkhazian" },
  { label: "Adyghe", value: "Adyghe" },
  { label: "African", value: "African" },
  { label: "Afro-Caribbean", value: "Afro-Caribbean" },
  { label: "Albanian", value: "Albanian" },
  { label: "Algerian", value: "Algerian" },
  { label: "Altaic", value: "Altaic" },
  { label: "Arab", value: "Arab" },
  { label: "Arab American", value: "Arab American" },
  { label: "Arab-Berber", value: "Arab-Berber" },
  { label: "Other", value: "Other" },
];

export default function SignupCard() {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    ethnicity: "",
    gender: "",
    mainDiagnostic: "",
    hasPreviousGeneticScreening: false,
    agreeWithTerms: false,
  });

  const handleChange = (name: string, value: string | boolean) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSignup = async (e: any) => {
    e.preventDefault();
    const data = {
      Name: state.firstName,
      FamilyName: state.lastName,
      Email: state.email,
      Gender: state.gender,
      Age: "",
      Ethnicity: state.ethnicity,
      MainDiagnostic: state.mainDiagnostic,
    };

    if (!state.agreeWithTerms) return;

    try {
      setLoading(true);
      const response = await signUpPatient(data);
      setLoading(false);

      if (response.Error) {
        alert(response.Payload || "Error signing up. Please try again.");
        return;
      } else {
        setMessage("Thank you for signing up! We will send you an email soon with your credentials.");
      }
    } catch (error) {
      console.error(error);
      alert("Error signing up. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCountries()
      .then((data) => {
        setCountries(
          data.map((item: any) => ({
            label: item.country,
            value: item.country,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (message) {
    return (
      <Container>
        <Stack spacing="8">
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Image src={"../TMA_Logo.png"} />
          </Stack>

          <Alert status="success">
            <AlertIcon />
            {message}
          </Alert>

          <Button colorScheme="blue" onClick={() => window.location.replace("/login")}>
            Go to login
          </Button>
        </Stack>
      </Container>
    );
  }

  return (
    <Flex minH={"100vh"} direction="column" align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing="6">
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Image src={"../TMA_Logo.png"} />
        </Stack>
      </Stack>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} textAlign={"center"}>
            Sign up
          </Heading>
        </Stack>
        <Box as="form" rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8} onSubmit={handleSignup}>
          <Stack spacing={4}>
            <HStack>
              <Box>
                <FormControl id="firstName" isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    value={state.firstName}
                    required
                    onChange={(e) => handleChange("firstName", e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl id="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    value={state.lastName}
                    required
                    onChange={(e) => handleChange("lastName", e.target.value)}
                  />
                </FormControl>
              </Box>
            </HStack>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={state.email}
                required
                onChange={(e) => handleChange("email", e.target.value)}
              />
            </FormControl>

            <FormControl id="previous-genetic-screening" isRequired>
              <FormLabel>Gender at birth</FormLabel>
              <Select
                options={[
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                ]}
                required
                onChange={({ value }: any) => handleChange("gender", value)}
              />
            </FormControl>

            <FormControl id="country" isRequired>
              <FormLabel>Country</FormLabel>
              <Select options={countries} required onChange={({ value }: any) => handleChange("country", value)} />
            </FormControl>

            <FormControl id="ethnicity" isRequired>
              <FormLabel>Ethnicity</FormLabel>
              <Select options={ethnicities} required onChange={({ value }: any) => handleChange("ethnicity", value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Diagnosis *</FormLabel>
              <VirtualizedSelect
                name="diagnosis"
                options={diagnosisOptions}
                onChange={({ value }: any) => handleChange("mainDiagnostic", value)}
                required
                placeholder="Select or search diagnosis.."
              />
            </FormControl>

            <FormControl id="previous-genetic-screening" isRequired>
              <FormLabel>Have you had any genetic screening done previously?</FormLabel>
              <Select
                options={[
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                ]}
                required
                onChange={({ value }: any) => handleChange("hasPreviousGeneticScreening", value === "yes")}
              />
            </FormControl>

            <Stack spacing={10} pt={2}>
              <Checkbox
                checked={state.agreeWithTerms}
                onChange={(e) => {
                  handleChange("agreeWithTerms", e.target.checked);
                }}
              >
                I agree to the{" "}
                <a href="/terms-and-conditions">
                  <strong>TMA Terms of Service</strong>
                </a>{" "}
                and{" "}
                <a href="/consent-form">
                  <strong>Consent Forms</strong>
                </a>
              </Checkbox>

              <Button
                loadingText="Submitting"
                size="lg"
                type="submit"
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                isDisabled={!state.agreeWithTerms || loading}
              >
                {loading ? "Signing up" : "Sign up"}
                {loading && <Spinner />}
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={"center"}>
                Already a user?{" "}
                <Link color={"blue.400"} href="/login">
                  Login
                </Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
