import React from "react";
import { Box, Flex, Image, Input, InputGroup, InputRightElement, IconButton, useToast } from "@chakra-ui/react";
import { CheckCircleIcon, ChatIcon, DownloadIcon } from "@chakra-ui/icons";
import { MdSend } from "react-icons/md";

const ChatMessage = ({ message, isSent }) => {
  return (
    <Box w="full" textAlign={isSent ? "right" : "left"}>
      <Flex alignItems="center" mb={2}>
        <Box
          p={2}
          rounded="lg"
          display="inline-block"
          bgColor={isSent ? "blue.600" : "gray.300"}
          color={isSent ? "white" : "gray.600"}
          borderRadius={isSent ? "br" : "bl"}
          marginLeft={isSent ? "auto" : "none"}
        >
          {message}
        </Box>
      </Flex>
    </Box>
  );
};

const Chat = () => {
  const toast = useToast();

  const handleSendMessage = () => {
    // Implement your logic for sending a message
    toast({
      title: "Message sent!",
      description: "Your message has been sent successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex
      flex={1}
      p={2}
      sm={{ p: 6 }}
      justifyContent="space-between"
      flexDirection="column"
      h="90vh"
      border="1px solid lightgray"
    >
      {/* Chat Header */}
      <Flex alignItems="center" justifyContent="space-between" py={3} borderBottom="2px" borderColor="gray.200">
        <Box fontSize="2xl" mt={1} color="gray.700">
          Get Insight
        </Box>
      </Flex>

      {/* Chat Messages */}
      <Box id="messages" flex={1} flexDirection="column" p={3} overflowY="auto">
        <ChatMessage
          message="Fantastic! I’ve found some articles that I think will help us discover some valuable insights. What would you like to do next? Let’s explore this research together!"
          isSent={false}
        />
        <ChatMessage message="Give me some treatment insight" isSent={true} />
        {/* Add more chat messages here */}
      </Box>

      {/* Chat Input */}
      <Flex borderTop="2px" borderColor="gray.200" p={4} mb={2} sm={{ mb: 0 }}>
        <InputGroup w="full">
          <Input
            type="text"
            placeholder="Write your message here..."
            color="gray.600"
            focusBorderColor="blue.500"
            _placeholder={{ color: "gray.600" }}
            bg="gray.200"
            rounded="md"
          />
          <InputRightElement>
            <IconButton
              aria-label="Emoji"
              icon={<MdSend />}
              backgroundColor="transparent"
              h={9}
              w={9}
              rounded="full"
              transition="duration: 500ms"
              color="gray.500"
              _hover={{ bgColor: "gray.300" }}
              focus="outline-none"
            />
          </InputRightElement>
        </InputGroup>
      </Flex>
    </Flex>
  );
};

export default Chat;
