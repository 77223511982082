import { Sidebar, MobileNavbar } from "./Navigation";
import { Box } from "@chakra-ui/react";
import useDimensions from "../hooks/useDimensions";

const Layout = (props: any) => {
  const { isDesktop } = useDimensions();

  return (
    <Box display="flex" flexDirection={["column", "column", "row"]}>
      {isDesktop ? <Sidebar /> : <MobileNavbar />}
      <Box
        height="100vh"
        marginTop={isDesktop ? "none" : "60px"}
        backgroundColor="brand.gray4"
        width="100%"
        overflow="scroll"
        padding={isDesktop ? "16px" : "8px"}
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "&::-webkit-scrollbar-track": {
            display: "none",
          },
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default Layout;
