export const statuses = [
  "ACCOUNT_CONSENT_AGREED",
  "ACCOUNT_CONTACT_INFO",
  "ACCOUNT_SELF_INPUT_DIAGNOSIS",
  "ACCOUNT_PDF_INPUT",
  "WGS_CONSENT",
  "WGS_KIT_SHIPPED",
  "WGS_KIT_RECEIVED",
  "WGS_REPORT_READY",
  "GENETIC_COUNSELING_CONSENT",
  "GENETIC_COUNSELING_APPOINTMENT_BOOKED",
  "GENETIC_COUNSELING_APPOINTMENT_COMPLETED",
  "GENETIC_COUNSELING_REPORT_READY",
];

export const stepObjects = [
  {
    title: "Account",
    tasks: [
      { key: "ACCOUNT_CONSENT_AGREED", title: "Consent agreed", completed: false },
      { key: "ACCOUNT_CONTACT_INFO", title: "Contact Info", completed: false },
      { key: "ACCOUNT_SELF_INPUT_DIAGNOSIS", title: "Self input diagnosis", completed: false },
      { key: "ACCOUNT_PDF_INPUT", title: "PDF Input + EMR pull Consent", completed: false },
    ],
  },
  {
    title: "WGS",
    tasks: [
      { key: "WGS_CONSENT", title: "WGS Consent", completed: false },
      { key: "WGS_KIT_SHIPPED", title: "Shipped to Patient", completed: false },
      { key: "WGS_KIT_RECEIVED", title: "WGS Received", completed: false },
      { key: "WGS_REPORT_READY", title: "WGS report ready", completed: false },
    ],
  },
  {
    title: "Genetic counselling",
    tasks: [
      { key: "GENETIC_COUNSELING_CONSENT", title: "GC Consent", completed: false },
      { key: "GENETIC_COUNSELING_APPOINTMENT_BOOKED", title: "GC Appointment booked", completed: false },
      { key: "GENETIC_COUNSELING_APPOINTMENT_COMPLETED", title: "GC Appointment completed", completed: false },
      { key: "GENETIC_COUNSELING_REPORT_READY", title: "GC Report ready", completed: false },
    ],
  },
];

export const getSteps = (currentStatus: string) => {
  if (!currentStatus) {
    return stepObjects;
  }

  if (!statuses.includes(currentStatus)) {
    throw new Error("Invalid status");
  }

  let found = false;

  for (let step of stepObjects) {
    if (found) break;

    const { tasks } = step;

    for (const task of tasks) {
      if (task.key !== currentStatus) {
        task.completed = true;
      } else {
        task.completed = true;
        found = true;
        break;
      }
    }
  }

  return stepObjects;
};

export const stepsReducer = (count: number, step: (typeof stepObjects)[number]) => {
  if (step.tasks.every((task) => task.completed)) {
    count = count + 1;
    return count;
  }
  return count;
};
