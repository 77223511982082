import {
  Box,
  Container
} from '@chakra-ui/react';

export const RCContainer = (props: any) => {
  return (
      <Box
        rounded={"lg"}
        width={"100%"}
        bg={"white"}
        px={"6px"}
        py={"6px"}
        onClick={props.onClick}
        height={props.height}
        alignItems={props.alignItems}
        alignContent={props.alignContent}
        display={props.display}
        {...props}
      >
        {props.children}
      </Box>
  )
}



