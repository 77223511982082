import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  List,
  ListIcon,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { PDFPage } from "pdf-lib";
import { useEffect, useState } from "react";
import { FiEye } from "react-icons/fi";
import { useRareCare } from "src/contexts/rarecare.context";
import { LabelItemObject, LabelsObject } from "src/types/file-labels.types";
import Loader from "../Loader";
import { modifyPDF } from "./helpers";
import PDFViewer from "./PdfViewer";

interface DrawerProps {
  onClose: () => void;
  isOpen: boolean;
  fileName: string;
  fileLabels?: LabelsObject | null;
  initialPage?: number;
}

export default function FileExplorerDrawer({
  initialPage = 1,
  fileName,
  onClose,
  isOpen,
  fileLabels = null,
}: DrawerProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [fileBytes, setFileBytes] = useState<Uint8Array | []>([]);
  const [labels, setLabels] = useState<LabelsObject | null>(fileLabels);
  const [pages, setPages] = useState<PDFPage[]>([]);
  const [prepared, setPrepared] = useState(false);

  const toast = useToast({ position: "top-right" });

  const { medications, diseases, diagnosis } = labels || {};

  const { RareCare } = useRareCare();

  useEffect(() => {
    async function preparePDF() {
      try {
        const pdfBuffer = await RareCare?.downloadFile(fileName);
        if (!pdfBuffer) throw new Error("Could not download file");
        const _labels = labels || (await RareCare?.getFileLabels(fileName)).Payload;
        const { pdfBytes, pages } = await modifyPDF(pdfBuffer, _labels);
        if (!labels) setLabels(_labels);
        setFileBytes(pdfBytes);
        setPages(pages);
      } catch (error: any) {
        toast({ status: "error", description: error.message });
      } finally {
        setIsLoading(false);
      }
    }
    if (!prepared) {
      preparePDF();
      setPrepared(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RareCare, prepared, labels, fileName]);

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="full">
      <DrawerOverlay />
      <DrawerContent>
        {isLoading ? (
          <HStack width="100vw" height="100vh">
            <Loader />
          </HStack>
        ) : (
          <>
            <DrawerCloseButton />
            <DrawerHeader>{`File explorer`}</DrawerHeader>
            <DrawerBody background="brand.gray4">
              <Flex height="90vh" gap="8px">
                <Box width="35%" height="100%" background="white" borderRadius="8px" paddingBottom="20px">
                  <Tabs variant="enclosed" maxHeight="100%" overflow="hidden">
                    <TabList>
                      <Tab>Medications</Tab>
                      <Tab>Diseases</Tab>
                      <Tab>Diagnosis</Tab>
                    </TabList>

                    <TabPanels height={"90vh"} overflowY="scroll">
                      <TabPanel>
                        <PanelData
                          type="medications"
                          data={medications || []}
                          onItemClick={(item) => setCurrentPage(item.Page)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <PanelData
                          type="disease"
                          data={diseases || []}
                          onItemClick={(item) => setCurrentPage(item.Page)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <PanelData
                          type="diagnosis"
                          data={diagnosis || []}
                          onItemClick={(item) => setCurrentPage(item.Page)}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
                <Flex
                  flex="1"
                  maxWidth="64%"
                  justifyContent="center"
                  height="100%"
                  background="white"
                  borderRadius="8px"
                >
                  <PDFViewer
                    key={fileName + currentPage}
                    fileBytes={fileBytes as Uint8Array}
                    pages={pages}
                    fileName={fileName}
                    page={currentPage}
                  />
                </Flex>
              </Flex>
            </DrawerBody>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
}

interface PanelDataProps {
  data: LabelItemObject[];
  type: string;
  onItemClick: (data: LabelItemObject) => void;
}

const labelColorMap: Record<string, string> = {
  medications: "blue",
  disease: "red",
  diagnosis: "green",
};

function PanelData({ data, type, onItemClick }: PanelDataProps) {
  const color = labelColorMap[type];

  return data?.length ? (
    <List spacing={3}>
      {data
        .filter((d) => d.Geometry?.BoundingBox)
        .map((item, index) => (
          <ListItem
            key={index}
            background={`${color}.100`}
            color={`${color}.800`}
            padding="4px"
            borderRadius="4px"
            cursor="pointer"
            _hover={{ background: `${color}.200` }}
            onClick={() => onItemClick(item)}
          >
            <HStack height="100%">
              <Text flex="1" maxWidth="100%" wordBreak="break-word">
                {item.Text}
              </Text>
              <ListIcon as={FiEye} color={`${color}.700`} />
            </HStack>
          </ListItem>
        ))}
    </List>
  ) : (
    <Text>No {type} data found in this file</Text>
  );
}
