import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import Loader from "src/components/Loader";
import { useRareCare } from "../../../contexts/rarecare.context";

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AddNewTimelineDrawer({ isOpen, onClose: closeDrawer }: DrawerProps) {
  const [updating, setUpdating] = useState(false);
  const { RareCare, dispatch } = useRareCare();
  const toast = useToast({ position: "top-right" });

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    if (!RareCare) return;

    event.preventDefault();

    const formData = new FormData(event.target);
    const formDataObject = Object.fromEntries(formData.entries());
    const itemDate = formDataObject.date as string;

    const dateObject = new Date(itemDate);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth()).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    const formattedDate = `${day}/${month}/${year}`;
    setUpdating(true);
    const { error, message, data } = await RareCare.addTimelineItem({
      date: formattedDate,
    });
    setUpdating(false);

    if (error || data.Error) {
      toast({ status: "error", description: message });
    } else {
      closeDrawer();
      setUpdating(false);
      toast({ status: "success", description: message });
      dispatch({
        type: "setTimelineItem",
        payload: {
          date: formattedDate,
        },
      });
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={closeDrawer} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add timeline item</DrawerHeader>

        {updating ? <Loader /> : null}

        <form onSubmit={handleSubmit}>
          <DrawerBody>
            <VStack gap="16px">
              <FormControl>
                <FormLabel>Date *</FormLabel>
                <Input name="date" type="date" required disabled={updating} />
                <FormHelperText color="brand.gray2">
                  <em>Date of the timeline item</em>
                </FormHelperText>
              </FormControl>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={closeDrawer} disabled={updating}>
              Cancel
            </Button>
            <Button type="submit" colorScheme="blue" disabled={updating}>
              Save
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
}
