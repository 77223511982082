import {
  Badge,
  Box,
  Divider,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import Layout from "src/components/Layout";
import Chat from "src/components/Chat";
import { debounce } from "src/utils";
import { SearchIcon } from "@chakra-ui/icons";
import { getScholar } from "src/services/DocOne";
import { useState } from "react";
import Loader from "src/components/Loader";
import React from "react";

export default function Chatbot() {
  const [loading, setLoading] = useState(false);
  const [scholars, setScholars] = useState<any>([]);

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { value } = event.target;
      if (!value?.length) return;

      setLoading(true);
      const response = await getScholar({ query: value });
      setScholars(response.items);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      alert("An error happened. Try again later");
    }
  };

  return (
    <Layout>
      <Box position="relative" width="full" mb="8px">
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.500" />} paddingLeft="3" />
          <Input
            isDisabled={loading}
            boxShadow="0px 0px 10px rgba(35, 70, 112, 0.03)"
            borderRadius="100px"
            backgroundColor="white"
            color="gray.900"
            fontSize="sm"
            focusBorderColor="blue.500"
            width="full"
            paddingLeft="10"
            paddingTop="2.5"
            paddingBottom="2.5"
            placeholder={`Enter topics separated by semicolons (example: Ad5; PSA; brachyury; MUC-1; prostate cancer)`}
            onChange={debounce(handleSearch, 800)}
          />
        </InputGroup>
      </Box>

      <Stack direction="row" mb="2">
        <Badge padding="2" borderRadius="100px" colorScheme="green">
          Prostate cancer
        </Badge>
        <Badge padding="2" borderRadius="100px" colorScheme="green">
          ADHD
        </Badge>

        <Badge padding="2" borderRadius="100px" colorScheme="green">
          Ad5
        </Badge>

        <Badge padding="2" borderRadius="100px" colorScheme="green">
          Brachyury
        </Badge>

        <Badge padding="2" borderRadius="100px" colorScheme="green">
          Acro-renal-mandibular syndrome
        </Badge>
      </Stack>

      <Flex>
        <Box flex="1" border="1px solid lightgray" padding="10px" maxHeight="90vh" overflow="scroll">
          <Flex alignItems="center" justifyContent="space-between" py={3} borderBottom="2px" borderColor="gray.200">
            <Box fontSize="2xl" mt={1} color="gray.700">
              Articles
            </Box>
          </Flex>

          {loading && (
            <Box>
              <Loader />
            </Box>
          )}

          {!loading && !scholars.length && (
            <Text color="gray.500">
              <strong>No articles! Use the search input above to search for keywords. </strong>
            </Text>
          )}

          {!loading && (
            <VStack align="left">
              {scholars.map((scholar: any) => (
                <Box background="white" padding="16px">
                  <Heading
                    size="sm"
                    color="blue.700"
                    as="a"
                    href={scholar.url}
                    target="_blank"
                    cursor="pointer"
                    _hover={{ textDecoration: "underline", color: "blue.600" }}
                  >
                    {scholar.title}
                  </Heading>
                  <Text color="gray.500">
                    <strong>Publication year: </strong> {scholar.publication_year}
                  </Text>
                  <Text color="gray.500">
                    <strong>Authors: </strong>
                    {scholar.authors?.map((author: any) => author.author_name).join(", ") || "N/A"}
                  </Text>
                </Box>
              ))}
            </VStack>
          )}
        </Box>

        <Chat />
      </Flex>
    </Layout>
  );
}
