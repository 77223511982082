import {
  Accordion,
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent, useMemo, useRef, useState } from "react";
import EmptyState from "../../../components/EmptyState";
import { useRareCare } from "../../../contexts/rarecare.context";
import { paginate } from "../../../utils";
import { RoundedArrowLeftIcon, RoundedArrowRightIcon, RoundedPlusIcon } from "../../Icons";
import AddNewTimelineDrawer from "./AddNewTimelineDrawer";
import { TimelineItemAccordion } from "./TimelineItem";

const mapToArray = (object: Record<string, string>) => {
  return Object.entries(object).map(([key, value]) => ({ name: key, description: value }));
};

const MapTimelineData = (timeline: Record<string, any>) => {
  let timelineItems: any = [];
  for (const date in timeline) {
    const item = {
      date,
      diagnostics: mapToArray(timeline[date].Diagnostics),
      procedures: mapToArray(timeline[date].Procedures),
      symptoms: mapToArray(timeline[date].Symptoms),
    };
    timelineItems.push(item);
  }
  return timelineItems;
};

const MedicalJourneyTimeline: FunctionComponent = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const { User, Case } = useRareCare();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const timelineContentRef = useRef<HTMLDivElement>(null);

  const timelineItems = MapTimelineData(Case?.Timeline || {});
  const paginatedTimelineItems = useMemo(() => {
    return paginate(timelineItems, { perPage: 5 });
  }, [timelineItems]);

  if (!User || !Case) {
    return null;
  }

  const currentPageData = paginatedTimelineItems[currentPage];
  const totalPage = paginatedTimelineItems.length;
  const hasNextPage = currentPage < totalPage - 1;
  const hasPreviousPage = currentPage > 0;

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);
      timelineContentRef.current?.scroll({ top: 0 });
    }
  };

  const handlePreviousPage = () => {
    if (hasPreviousPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Box background="white" padding="40px" borderRadius="8px" height={"500px"} width={"100%"} overflow="hidden">
      <HStack justify="space-between">
        <Heading color="grayOne" fontSize="20px" fontWeight="700" width="118px">
          Activity
        </Heading>

        {timelineItems.length && (
          <VStack>
            <HStack width="118px" justify="center">
              <IconButton
                aria-label="Previous button"
                variant="ghost"
                icon={<RoundedArrowLeftIcon />}
                onClick={handlePreviousPage}
                disabled={!hasPreviousPage}
              />
              <IconButton
                aria-label="Next button"
                variant="ghost"
                icon={<RoundedArrowRightIcon />}
                onClick={handleNextPage}
                disabled={!hasNextPage}
              />
            </HStack>
            <Text as="small" color="brand.gray2" textAlign="center">
              page {currentPage + 1} of {totalPage}
            </Text>
          </VStack>
        )}

        <Button variant="ghost" onClick={onOpen}>
          <HStack align="center">
            {!isMobile ? (
              <Text as="span" color="#496CA1">
                Add New
              </Text>
            ) : null}
            <RoundedPlusIcon />
          </HStack>
        </Button>
      </HStack>

      {timelineItems.length ? (
        <VStack align="left" marginTop="33px" height="100%" overflow="scroll" ref={timelineContentRef}>
          <Accordion defaultIndex={[0, 1, 2, 3, 4]} allowMultiple width="90%" borderRadius="8px" marginBottom="100px">
            {currentPageData.map((data) => (
              <TimelineItemAccordion key={data.date} data={data} />
            ))}
          </Accordion>
        </VStack>
      ) : (
        <EmptyState header="No data available" description="Sorry, there is no data available to show." />
      )}
      <AddNewTimelineDrawer isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default MedicalJourneyTimeline;
