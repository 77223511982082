import { ChangeEvent, useState } from "react";
import {
  Button,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Drawer,
  DrawerFooter,
  FormControl,
  FormLabel,
  FormHelperText,
  useToast,
} from "@chakra-ui/react";

import { useRareCare } from "src/contexts/rarecare.context";
import Loader from "src/components/Loader";
import VirtualizedSelect from "src/components/VirtualizedSelect";
import diagnosisJson from "src/utils/data/diagnosis.data.json";

const diagnosisOptions = diagnosisJson
  .map((v) => ({ label: v.label, value: v.label }))
  .sort((a, b) => a.label.localeCompare(b.label));

interface DiagnosisDrawerProps {
  initialValue: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function DiagnosisEditDrawer({ initialValue, isOpen, onClose: closeDrawer }: DiagnosisDrawerProps) {
  const [updating, setUpdating] = useState(false);
  const { RareCare, refreshCase } = useRareCare();
  const toast = useToast({ position: "top-right" });

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    if (!RareCare) return;

    event.preventDefault();

    const formData = new FormData(event.target);
    const formDataObject = Object.fromEntries(formData.entries());
    const diagnosis = formDataObject.diagnosis as string;

    setUpdating(true);
    const { error, message, data } = await RareCare.updateCaseDetails({
      MainDiagnosis: diagnosis,
    });
    setUpdating(false);

    if (error || data.Error) {
      toast({ status: "error", description: message });
    } else {
      closeDrawer();
      toast({ status: "success", description: message });
      refreshCase();
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={closeDrawer} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit or Add Diagnosis</DrawerHeader>

        {updating ? <Loader /> : null}

        <form onSubmit={handleSubmit}>
          <DrawerBody minHeight="400px">
            <FormControl>
              <FormLabel>Diagnosis *</FormLabel>
              <VirtualizedSelect
                name="diagnosis"
                defaultValue={initialValue ? { label: initialValue, value: initialValue } : null}
                options={diagnosisOptions}
                required
                placeholder="Select or search diagnosis.."
                isDisabled={updating}
              />
              <FormHelperText color="brand.gray2">
                <em>Select or search diagnosis.</em>
              </FormHelperText>
            </FormControl>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={closeDrawer} disabled={updating}>
              Cancel
            </Button>
            <Button type="submit" colorScheme="blue" disabled={updating}>
              Save
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
}
