import { Button, Text, VStack } from "@chakra-ui/react";
import { MouseEventHandler } from "react";

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  title: string;
  logoSrc: string;
  width?: string;
};

export default function ActionButton(props: Props) {
  return (
    <Button
      padding="16px"
      background="white"
      width={props.width || "calc(50% - 8px)"}
      minWidth="130px"
      height="calc(50% - 8px)"
      onClick={props.onClick}
    >
      <VStack>
        <img alt="action icon" src={props.logoSrc} />
        <Text color="#496CA1" as="span" wordBreak="break-word">
          {props.title}
        </Text>
      </VStack>
    </Button>
  );
}
