import React, { useCallback, useContext, useEffect, useReducer } from "react";
import { RareCareCase } from "../services/RareCare/RareCare";
import { useAuth } from "./auth.context";

export interface RareCareContextType {
  User: RareCare.user | null;
  Case: RareCare.Case | null;
  RareCare: RareCareCase | null;
  loading: Boolean;
  refreshCase: Function;
  error: boolean;
  errorMessage: string | null;
  dispatch: React.Dispatch<ActionType>;
}

type ActionType = {
  type: "initialize" | "setCase" | "error" | "setTimepoint" | "setTimelineItem" | "setUser";
  payload: Partial<Omit<RareCareContextType, "dispatch" | "refreshCase">> & {
    type?: string;
    date?: string;
    name?: string;
    description?: string;
  };
};

const RareCareContext = React.createContext<RareCareContextType | undefined>(undefined);

const initialState: RareCareContextType = {
  User: null,
  Case: null,
  RareCare: null,
  loading: true,
  error: false,
  errorMessage: null,
  refreshCase: () => {},
  dispatch: () => {},
};

function reducer(state: RareCareContextType, action: ActionType): RareCareContextType {
  switch (action.type) {
    case "initialize": {
      const data = action.payload;
      return { ...state, ...data };
    }

    case "setUser": {
      const { User } = action.payload;
      return { ...state, User: { ...state.User, ...User } as RareCareContextType["User"] };
    }

    case "setCase": {
      const { Case } = action.payload;
      return { ...state, Case: Case as RareCareContextType["Case"] };
    }

    case "setTimelineItem": {
      const { payload } = action;
      const { date } = payload;
      const timeline = state.Case?.Timeline || {};
      const newTimelineItem = {
        [date as string]: {
          Diagnostics: {},
          Procedures: {},
          Symptoms: {},
        },
      };
      return {
        ...state,
        Case: {
          ...state.Case,
          Timeline: {
            ...newTimelineItem,
            ...timeline,
          },
        } as RareCareContextType["Case"],
      };
    }

    case "setTimepoint": {
      const { payload } = action;
      const { type, date, name, description } = payload;
      const timeline = structuredClone(state.Case?.Timeline || {});
      const timelineItem = timeline[date as string];
      timelineItem[type as string][name as string] = description;
      return {
        ...state,
        Case: {
          ...state.Case,
          Timeline: timeline,
        } as RareCareContextType["Case"],
      };
    }

    case "error": {
      const { payload } = action;
      return { ...state, ...payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const useRareCare = () => {
  const context = useContext(RareCareContext);

  if (context === undefined) {
    throw new Error("useRareCare must be used within a RareCareProvider");
  }

  const { user: authedUser, setUser } = useAuth();
  const { dispatch, RareCare, User: caseUser } = context;
  const user = caseUser || authedUser;

  const refreshCase = useCallback(() => {
    RareCare?.getCase().then((result) => {
      dispatch({
        type: "setCase",
        payload: {
          Case: result,
        },
      });
    });
  }, [RareCare, dispatch]);

  useEffect(() => {
    if (context.RareCare || !user.username) return;

    const initializeCase = async () => {
      try {
        const rareCareCase = new RareCareCase(user.username);
        await rareCareCase.Initialize();
        const result = await rareCareCase.getCase();

        rareCareCase
          .getAvatar()
          .then((avatar: string) => {
            // setUser({ ...user, avatarUrl: avatar });
            dispatch({
              type: "setUser",
              payload: {
                User: { ...user, avatarUrl: avatar },
              },
            });
          })
          .catch((err: Error) => {
            console.error(err);
          });

        dispatch({
          type: "initialize",
          payload: {
            User: user,
            Case: result,
            RareCare: rareCareCase,
            loading: false,
          },
        });
      } catch (error: any) {
        console.error(error);
        dispatch({
          type: "error",
          payload: {
            error: true,
            errorMessage: error.message,
          },
        });
      }
    };

    initializeCase();
  }, [dispatch, context.RareCare, user.username, user, setUser]);

  return {
    ...context,
    refreshCase,
  };
};

const RareCareProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { ...state, dispatch };
  return <RareCareContext.Provider value={value}>{props.children}</RareCareContext.Provider>;
};

export { RareCareProvider, useRareCare };
