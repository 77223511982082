import axios from "axios";

const api = axios.create({
  baseURL: "https://dev.doconeapis.com",
});

const setAuthorizationHeader = (token) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return fetchNewToken()
        .then((newToken) => {
          setAuthorizationHeader(newToken);
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          return axios(originalRequest);
        })
        .catch((error) => {
          console.error("Error refreshing token:", error);
        });
    }
    return Promise.reject(error);
  }
);

async function fetchNewToken() {
  try {
    const { data } = await api.get("/auth", {
      params: { secret: "a4744fe1-5769-4dcd-9646-19a41d513fe5" },
      headers: { Accept: "application/json" },
    });
    console.log(data);
    return data.access_token;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function getScholar({ query }) {
  try {
    const { data } = await api.get("/scholar", {
      params: { query },
      headers: { Accept: "application/json" },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export { getScholar };
