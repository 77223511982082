import { useState, useEffect } from "react";
import { FormControl, FormHelperText, FormLabel, Input, Spinner, VStack } from "@chakra-ui/react";
import { SingleValue, Props as SelectProps } from "react-select";
import axios from "axios";
import Select from "../Select";

type ReactSelectOption = SingleValue<{ label: string; value: string }>;

export interface AddressInfo {
  streetAddress: string;
  apartmentOrSuite: string;
  country: string;
  state: string;
  city: string;
  zipOrPostalCode: string;
}

interface Country {
  name: string;
  iso2: string;
  iso3: string;
  states: { name: string; state_code: string }[];
}

interface State {
  label: string;
  value: string;
}

interface City {
  label: string;
  value: string;
}

const fetchCountriesAndStates = async () => {
  try {
    const url = "https://countriesnow.space/api/v0.1/countries/states";
    const response = await axios.get(url);
    const responseData = response.data;
    const data = responseData.data;
    return data;
  } catch (error) {
    throw error;
  }
};

const fetchCities = async (country: string, state: string) => {
  try {
    const url = "https://countriesnow.space/api/v0.1/countries/state/cities";
    const response = await axios.post(url, { country, state });
    const responseData = response.data;
    const data = responseData.data;
    return data.map((city: string) => ({ label: city, value: city }));
  } catch (error) {
    throw error;
  }
};

const AddressForm = ({
  disabled,
  addressInfo,
  onChange,
}: {
  disabled: boolean;
  addressInfo: AddressInfo;
  onChange: Function;
}) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [loadingCities, setLoadingCities] = useState(false);

  const selectedCountry = addressInfo.country;
  const selectedState = addressInfo.state;
  const selectedCity = addressInfo.city;

  const shouldDisableFields = disabled || loadingCities;

  const getCountryStates = (country: string) => {
    const countryObject = countries.find((c) => c.name === country) as Country;
    const countryStates = countryObject.states.map(({ name }) => ({ label: name, value: name }));
    return countryStates;
  };

  if (selectedCountry && countries.length && !states.length) {
    const countryStates = getCountryStates(selectedCountry);
    if (countryStates.length) setStates(countryStates);
  }

  if (selectedState && !cities.length) {
    fetchCities(selectedCountry, selectedState).then((data) => setCities(data));
  }

  useEffect(() => {
    fetchCountriesAndStates()
      .then((data) => setCountries(data))
      .catch((e) => console.error("Error fetching countries:", e));
  }, []);

  const handleCountryChange = (selectedOption: { label: string; value: string }) => {
    const country = selectedOption.label;
    const countryStates = getCountryStates(country);
    setStates(countryStates);
    setCities([]);
    onChange({ ...addressInfo, country, state: "", city: "" });
  };

  const handleStateChange = async (selectedOption: ReactSelectOption) => {
    if (!selectedOption) return;
    const state = selectedOption.value;
    setLoadingCities(true);
    await fetchCities(selectedCountry, state)
      .then((data) => setCities(data))
      .catch((e) => console.error("Error fetching cities", e))
      .finally(() => setLoadingCities(false));
    onChange({ ...addressInfo, state, city: "" });
  };

  const handleCityChange = (selectedOption: ReactSelectOption) => {
    if (!selectedOption) return;
    const city = selectedOption.value;
    onChange({ ...addressInfo, city });
  };

  const handleChange = (field: string, value: string) => {
    onChange({
      ...addressInfo,
      [field]: value,
    });
  };

  return (
    <fieldset disabled={shouldDisableFields}>
      <VStack gap="6">
        <FormControl>
          <FormLabel>Street address</FormLabel>
          <Input
            name="streetAddress"
            type="text"
            placeholder="Street address"
            value={addressInfo.streetAddress}
            onChange={(e) => handleChange("streetAddress", e.target.value)}
          />
          <FormHelperText color="brand.gray2">
            <em>Enter your street address. i.e.: 123 Main Street</em>
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel>Apartement, suite, etc. (optional)</FormLabel>
          <Input
            name="apartmentOrSuite"
            value={addressInfo.apartmentOrSuite}
            type="text"
            placeholder="Apartment, suite, etc. (optional)"
            onChange={(e) => handleChange("apartmentOrSuite", e.target.value)}
          />
          <FormHelperText color="brand.gray2">
            <em>i.e.: Apt 4B</em>
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel>Country</FormLabel>
          <Select
            options={countries.map((country) => ({
              label: country.name,
              value: country.iso2,
            }))}
            onChange={(value) => handleCountryChange(value as any)}
            value={selectedCountry ? { label: selectedCountry, value: selectedCountry } : null}
            placeholder="Select country"
            isSearchable
            isDisabled={shouldDisableFields}
          />
          <FormHelperText color="brand.gray2">
            <em>Select or search for a country</em>
          </FormHelperText>
        </FormControl>

        {states.length ? (
          <FormControl>
            <FormLabel>State {loadingCities && <Spinner size={"sm"} />}</FormLabel>
            <Select
              options={states}
              onChange={handleStateChange as SelectProps["onChange"]}
              value={selectedState ? { label: selectedState, value: selectedState } : null}
              placeholder="Select State"
              isSearchable
              isDisabled={shouldDisableFields}
            />

            <FormHelperText color="brand.gray2">
              <em>Select or search for a state</em>
            </FormHelperText>
          </FormControl>
        ) : null}

        {cities.length ? (
          <FormControl>
            <FormLabel>City</FormLabel>
            <Select
              options={cities}
              onChange={(value) => handleCityChange(value as ReactSelectOption)}
              value={selectedCity ? { label: selectedCity, value: selectedCity } : null}
              placeholder="Select city"
              isSearchable
              isDisabled={shouldDisableFields}
            />
            <FormHelperText color="brand.gray2">
              <em>Select or search for a city</em>
            </FormHelperText>
          </FormControl>
        ) : null}

        <FormControl>
          <FormLabel>Zip / Postal code</FormLabel>
          <Input
            name="zipOrPostalCode"
            value={addressInfo.zipOrPostalCode}
            type="text"
            placeholder="Zip / Postal code"
            onChange={(e) => handleChange("zipOrPostalCode", e.target.value)}
          />
          <FormHelperText color="brand.gray2">
            <em>Enter your zip or postal code in the format XXXXX or XXXXX-XXXX</em>
          </FormHelperText>
        </FormControl>
      </VStack>
    </fieldset>
  );
};

export default AddressForm;
