import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTable, useSortBy, useGlobalFilter, useFilters } from "react-table";
import { useMemo } from "react";

import TableSearchInput from "./TableSearchInput";

const filterTypes = {
  text: (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      if (rowValue === undefined) return true;
      const theRowValue = String(rowValue).toLowerCase();
      const theFilterValue = String(filterValue).toLowerCase();
      return theRowValue.includes(theFilterValue);
    });
  },
};

export default function AppTable({
  columns,
  data,
  withSortBy = true,
  withFilters = true,
  shadow = true,
  withSearch = false,
  CustomSearchInputComponent = null,
}) {
  const defaultColumn = useMemo(() => ({ Filter: "" }), []);
  const tableOptions = { columns, data, defaultColumn };
  const tablePluginHooks = [];

  if (withSearch) tableOptions.filterTypes = filterTypes;
  if (withSearch) tablePluginHooks.push(useGlobalFilter);
  if (withFilters) tablePluginHooks.push(useFilters);
  if (withSortBy) tablePluginHooks.push(useSortBy);

  const reactTable = useTable(tableOptions, ...tablePluginHooks);
  const { getTableProps, getTableBodyProps, prepareRow } = reactTable;
  const { headerGroups, rows, state } = reactTable;
  const { preGlobalFilteredRows, setGlobalFilter } = reactTable;

  const getColumnSortByToggleProps = (column) => {
    if (!withSortBy) return {};
    return column.getSortByToggleProps();
  };

  return (
    <>
      {withSearch && (
        <TableSearchInput
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          CustomSearchInputComponent={CustomSearchInputComponent}
        />
      )}
      <Box pb={8} bg="white" overflowX="auto" pos="relative" boxShadow={shadow ? "md" : ""} rounded="md" minH="120px">
        <Table {...getTableProps()} fontSize="sm" w="full">
          <Thead fontSize="xs" bg="white" borderBottom="1px">
            {headerGroups.map((headerGroup, index) => (
              <Tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <Th key={index} {...column.getHeaderProps()} scope="col" py={4} px={4} minW="125px">
                    <Flex>
                      <Box
                        as="span"
                        display="flex"
                        alignItems="center"
                        gap={2}
                        {...column.getHeaderProps(getColumnSortByToggleProps(column))}
                      >
                        <Box opacity="0.5" fontWeight="medium" color="darkblue">
                          {column.render("Header")}
                        </Box>
                        <Box>{getSortDirectionIndicator(column)}</Box>
                      </Box>
                      <Box>{column.canFilter ? column.render("Filter") : null}</Box>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr key={row.id} {...row.getRowProps()} bg="white" borderBottom="1px">
                  {row.cells.map((cell, index) => {
                    return (
                      <Td key={index} {...cell.getCellProps()} py={2} px={4} maxW="200px">
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
}

function getSortDirectionIndicator(column) {
  if (!column.Header || column.disableSortBy) return null;
  if (!column.isSorted) {
    return <ArrowDownIcon color="gray" />;
  }
  if (column.isSortedDesc) {
    return <ArrowDownIcon />;
  }
  return <ArrowUpIcon />;
}
